import React from 'react';
import { Route } from 'react-router-dom';
import ChangePassword from './ChangePassword';
import SendPasswordReset from './SendPasswordReset';

export default [
  <Route exact path="/change-password/:id" component={ChangePassword} noLayout key={1} />,
  <Route
    exact
    path="/send-password-reset/:email?"
    component={SendPasswordReset}
    noLayout
    key={2}
  />,
];
