import gql from 'graphql-tag';

import { buildQuery } from 'ra-data-graphql-simple';

const GET_ONE_QUERY = gql`
  query gymUser($id: ID!) {
    data: gymUser(id: $id) {
      id
      email
      firstname
      lastname
      picture
      birthday
    }
  }
`;

export const GetOne = (introspection: any) => {
  const builtQuery = buildQuery(introspection);

  return (params: any) => {
    return {
      // Use the default query variables and parseResponse
      ...builtQuery('GET_ONE', 'GymUser', params),
      // Override the query
      query: GET_ONE_QUERY,
    };
  };
};
