import Maybe from 'graphql/tsutils/Maybe';
import { LocalGym, BookingFlow } from '../@types/common';

export const slugify = (text: string) => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export const AmountFormatter = {
  parse: (value: number) => {
    return value * 100;
  },
  format: (value: number) => {
    return value / 100;
  },
};

// eslint-disable-next-line
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const DAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export default {
  slugify,
};

export const sessionIDToUsableID = (sessionID: string): string => {
  const [idType, id] = atob(sessionID).split(':');
  return `${id}${idType}`;
};

export const usableIDToSessionID = (usableID: string): string => {
  const objectId = usableID.substring(0, 24);
  const idType = usableID.substring(24, usableID.length);
  return btoa(`${idType}:${objectId}`);
};

export const getCurrentGyms = (): LocalGym[] => {
  let gyms: Maybe<LocalGym | LocalGym[]>;
  try {
    gyms = JSON.parse(localStorage.getItem('currentGym')!);
    if (gyms) return Array.isArray(gyms) ? gyms : [gyms];
  } catch {
    // nothing to do here
  }
  return [];
};

export const shouldDisplayEmail = (gyms: LocalGym[]): boolean => {
  return gyms.some(
    (gym) =>
      ![BookingFlow.OPEN_ACCESS_WITHOUT_ERP, BookingFlow.UNKNOWN].includes(
        gym.bookingFlow
      )
  );
};

export const shouldDisplayCode = (gyms: LocalGym[]): boolean => {
  return gyms.some((gym) =>
    [
      BookingFlow.BOOKING_WITHOUT_ERP_SPECIAL,
      BookingFlow.OPEN_ACCESS_WITHOUT_ERP,
    ].includes(gym.bookingFlow)
  );
};

export const shouldDisplayOffer = (gyms: LocalGym[]): boolean => {
  return gyms.some(
    (gym) =>
      gym.bookingFlow === BookingFlow.BOOKING_WITHOUT_ERP_SPECIAL && !!gym.payWithPassCode
  );
};
