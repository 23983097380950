import {
  List,
  ListItem,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { AppBar, translate, useTranslate, useRedirect } from 'react-admin';
import { LocalGym, Manager, BookingFlow } from '../@types/common';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import UserMenu from './UserMenu';

const styles = makeStyles((theme) => ({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  logo: {
    height: '30px',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    transform: 'translate(-50%, 0)',
  },
  arrowGymChooser: {
    marginLeft: '10px',
    color: 'white',
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
    '& svg': {
      color: 'white',
    },
    boxShadow: 'none',
  },
}));

function GymChooser() {
  const translator = useTranslate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedItem, setSelectedItem] = useState<null | LocalGym>(null);
  const redirect = useRedirect();
  const classes = styles();

  const [gyms, setGyms] = useState<LocalGym[]>([]);
  const selectAll: LocalGym = {
    name: translator('gymChooser.all'),
    id: 'all',
    bookingFlow: BookingFlow.UNKNOWN,
  };

  useEffect(() => {
    let manager: Manager;
    let currentGym: LocalGym;
    const selectAll: LocalGym = {
      name: translator('gymChooser.all'),
      id: 'all',
      bookingFlow: BookingFlow.UNKNOWN,
    };
    try {
      const content = localStorage.getItem('manager');
      if (!content) return console.error("We don't have a gym list");
      manager = JSON.parse(content);

      if (!manager || !manager.gyms || manager.gyms.length === 0)
        return console.error('No gyms for this gym manager');
      manager.gyms = manager.gyms.sort((a, b) => (a.name < b.name ? -1 : 1));
      setGyms(manager.gyms);
      currentGym = JSON.parse(localStorage.getItem('currentGym')!);
      if (currentGym) {
        if (
          !Array.isArray(currentGym) &&
          !manager.gyms.some((gym) => gym.id === currentGym.id)
        )
          return console.log('Could not find the selected gym in the gym list');
        if (Array.isArray(currentGym)) setSelectedItem(selectAll);
        else setSelectedItem(currentGym);
      }
    } catch (e) {
      console.log(e);
      // do something ?
    }
  }, [translator]);

  // TODO: What should we do here ?
  if (!gyms || gyms.length === 0) return <div />;

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    if (gyms.length > 1) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    if (index >= gyms.length) {
      setSelectedItem(selectAll);
      localStorage.setItem('currentGym', JSON.stringify(gyms));
    } else {
      setSelectedItem(gyms[index]);
      localStorage.setItem('currentGym', JSON.stringify(gyms[index]));
    }
    redirect('/Pass');
    window.location.reload();
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <List component="nav" aria-label="Device settings">
        <ListItem
          button
          disabled={gyms.length <= 1}
          aria-haspopup="true"
          aria-controls="lock-menu"
          onClick={handleClickListItem}
          id="gym-select-button"
        >
          <Typography style={{ color: 'white', fontSize: '20px', fontWeight: 600 }}>
            {selectedItem ? selectedItem.name : 'Chosir une salle'}
          </Typography>
          {Boolean(!anchorEl) && (
            <ArrowDropDownIcon className={classes.arrowGymChooser} />
          )}
        </ListItem>
      </List>
      {gyms.length > 1 && (
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {[...gyms, selectAll].map((gym, index) => (
            <MenuItem
              key={gym.id}
              selected={Boolean(selectedItem && gym.id === selectedItem.id)}
              onClick={(event: any) => handleMenuItemClick(event, index)}
              id={`gym-select-${index}`}
            >
              {gym.name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
}

const CustomUserMenu = translate(({ ...props }: any) => (
  <div id="app-bar-profile-div">
    <UserMenu {...props} />
  </div>
));

const CustomAppBar = ({ ...props }: any) => {
  const logout = <div id="logout-button">{props.logout}</div>;
  const classes = styles();
  return (
    <AppBar
      {...props}
      userMenu={<CustomUserMenu />}
      logout={logout}
      className={classes.appBar}
    >
      <GymChooser />
      <Typography color="inherit" className={classes.title} id="react-admin-title" />
      <img className={classes.logo} alt="" src={require('../images/logo_blanc.svg')} />
      <span className={classes.spacer} />
    </AppBar>
  );
};
export default CustomAppBar;
