import PassIcon from '@material-ui/icons/LocalActivity';
import { IResourceExport } from '../../@types/dataProvider';

import { PassShow } from './PassShow';
import { PassList } from './PassList';

import passProvider from './dataProvider';
import passValidationProvider from './dataProvider/validations';

export default {
  name: 'Pass',
  resources: {
    list: PassList,
    show: PassShow,
  },
  icon: PassIcon,
  dataProvider: {
    getOneName: 'pass',
    provider: passProvider,
  },
} as IResourceExport;

export const passesValidation: IResourceExport = {
  name: 'PassValidation',
  dataProvider: {
    getOneName: 'passValidation',
    provider: passValidationProvider,
  },
};
