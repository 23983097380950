import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { translate, MenuItemLink } from 'react-admin';
import { Menu as MenuItems } from '../resources';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import gymlibTheme from '../gymlibTheme';
import { setSidebarVisibility } from 'react-admin';
import { useDispatch } from 'react-redux';

interface MenuProps {
  onMenuClick: (a: any) => any;
  translate: any;
}

const useStyles = makeStyles({
  active: {
    '& a': {
      marginLeft: 8,
      marginRight: 8,
      borderRadius: 4,
      paddingLeft: 8,
      paddingRight: 8,
      marginBottom: 5,
      paddingTop: 10,
      paddingBottom: 10,
    },
    '& a[aria-current="page"]': {
      backgroundColor: `${gymlibTheme.palette.primary.main}30`,
    },
  },
});

const Menu = ({ translate, onMenuClick, open }: MenuProps & { open: boolean }) => {
  const dispatch = useDispatch();
  const isBig = useMediaQuery('(min-width:1200px)');
  if (!isBig && open) {
    dispatch(setSidebarVisibility(false));
  } else if (isBig && !open) {
    dispatch(setSidebarVisibility(true));
  }

  const classes = useStyles();
  return (
    <div className={classes.active}>
      {MenuItems.map((item) => (
        <MenuItemLink
          key={item.name}
          to={`/${item.name}`}
          primaryText={translate(`resources.${item.name}.name`, {
            smart_count: 2,
          })}
          leftIcon={item.icon && <item.icon />}
          onClick={onMenuClick}
          id={`menu-${item.name.toLowerCase()}`}
        />
      ))}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  open: state.admin.ui.sidebarOpen,
});

const enhance = compose<MenuProps & { open: boolean }, MenuProps & { open: boolean }>(
  withRouter,
  connect(mapStateToProps, {}),
  translate
);

export default enhance(Menu);
