import { AllPossibleRequestResolver } from '../../../../@types/dataProvider';

import { GetList, GetMany, GetManyReference } from './Many';

export default (introspection: any): AllPossibleRequestResolver => {
  return {
    GET_LIST: GetList(introspection),
    GET_MANY: GetMany(introspection),
    GET_MANY_REFERENCE: GetManyReference(introspection),
  };
};
