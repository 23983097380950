import UserIcon from '@material-ui/icons/Group';
import { IResourceExport } from '../../@types/dataProvider';

import { GymUserList } from './GymUserList';
import { UserShow } from './GymUserShow';

import gymUserProvider from './dataProvider';

export default {
  name: 'GymUser',
  resources: {
    list: GymUserList,
    show: UserShow,
  },
  icon: UserIcon,
  dataProvider: {
    getOneName: 'gymUser',
    provider: gymUserProvider,
  },
} as IResourceExport;
