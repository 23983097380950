import { IResourceExport } from './dataProvider';
declare global {
  interface Window {
    hj?: any;
  }
}

export type MenuItems = IResourceExport;

export type Menu = MenuItems[];

export interface Manager {
  email: string;
  gyms: LocalGym[];
}

export interface LocalGym {
  name: string;
  id: string;
  bookingFlow: BookingFlow;
  payWithPassCode?: boolean;
}

export enum BookingFlow {
  OPEN_ACCESS_WITHOUT_ERP = 'OPEN_ACCESS_WITHOUT_ERP',
  OPEN_ACCESS_WITH_ERP = 'OPEN_ACCESS_WITH_ERP',
  BOOKING_WITHOUT_ERP_SPECIAL = 'BOOKING_WITHOUT_ERP_SPECIAL',
  BOOKING_WITHOUT_ERP_REGULAR = 'BOOKING_WITHOUT_ERP_REGULAR',
  BOOKING_WITH_ERP = 'BOOKING_WITH_ERP',
  IN_APP_BOOKING = 'IN_APP_BOOKING',
  UNKNOWN = 'UNKNOWN',
}

export enum PassActions {
  PUNCH_PASS = 'punchPass',
  GPS_ANOMALY = 'declareGPSAnomaly',
  VALIDATE_BOOKING = 'validateBooking',
  LATE_CANCELLED = 'lateCancelled',
  CANCEL_LATE_CANCELLED = 'cancelLateCancelled',
  NO_SHOW = 'noShow',
  CANCEL_NO_SHOW = 'cancelNoShow',
  SYNC_ERP_STATUS = 'syncErpStatus',
}

export enum RequestAction {
  ACCEPT = 'acceptRequest',
  DECLINE = 'declineRequest',
}

export const requestType = {
  [RequestAction.ACCEPT]: 'ACCEPT',
  [RequestAction.DECLINE]: 'DECLINE',
};

export enum SessionState {
  USABLE = 'USABLE',
  CHECKED_IN = 'CHECKED_IN',
  EXPIRED = 'EXPIRED',
  REFUNDED = 'REFUNDED',
  REQUEST_TO_SEND = 'REQUEST_TO_SEND',
  REQUEST_SENT = 'REQUEST_SENT',
  REQUEST_REFUSED = 'REQUEST_REFUSED',
  CREATED = 'CREATED',
  BOOKED = 'BOOKED',
  NO_SHOW = 'NO_SHOW',
  LATE_CANCELLED = 'LATE_CANCELLED',
  EARLY_CANCELLED = 'EARLY_CANCELLED',
  CREDIT_TO_TRANSFER = 'CREDIT_TO_TRANSFER',
  CREDIT_TRANSFERED = 'CREDIT_TRANSFERED',
}

export const bookingFlowSessionState = {
  [BookingFlow.OPEN_ACCESS_WITHOUT_ERP]: [
    SessionState.USABLE,
    SessionState.CHECKED_IN,
    SessionState.EXPIRED,
    SessionState.REFUNDED,
  ],
  [BookingFlow.OPEN_ACCESS_WITH_ERP]: [
    SessionState.USABLE,
    SessionState.CHECKED_IN,
    SessionState.EXPIRED,
    SessionState.REFUNDED,
  ],
  [BookingFlow.BOOKING_WITH_ERP]: [
    SessionState.BOOKED,
    SessionState.CHECKED_IN,
    SessionState.NO_SHOW,
    SessionState.EXPIRED,
    SessionState.REFUNDED,
  ],
  [BookingFlow.BOOKING_WITHOUT_ERP_REGULAR]: [
    SessionState.REQUEST_SENT,
    SessionState.REQUEST_REFUSED,
    SessionState.BOOKED,
    SessionState.CHECKED_IN,
    SessionState.NO_SHOW,
    SessionState.LATE_CANCELLED,
    SessionState.EXPIRED,
    SessionState.REFUNDED,
  ],
  [BookingFlow.BOOKING_WITHOUT_ERP_SPECIAL]: [
    SessionState.CREATED,
    SessionState.BOOKED,
    SessionState.CHECKED_IN,
    SessionState.NO_SHOW,
    SessionState.LATE_CANCELLED,
    SessionState.EXPIRED,
    SessionState.REFUNDED,
  ],
  [BookingFlow.IN_APP_BOOKING]: [
    SessionState.BOOKED,
    SessionState.CHECKED_IN,
    SessionState.NO_SHOW,
    SessionState.LATE_CANCELLED,
    SessionState.EARLY_CANCELLED,
    SessionState.REFUNDED,
  ],
  [BookingFlow.UNKNOWN]: [
    SessionState.USABLE,
    SessionState.CHECKED_IN,
    SessionState.EXPIRED,
    SessionState.REFUNDED,
  ],
};

export const getPassStatusForBookingFlows = (
  bookingFlows: BookingFlow[]
): SessionState[] => {
  const sessionsStates: SessionState[] = [];
  for (let i = 0; i < bookingFlows.length; i++) {
    for (let j = 0; j < bookingFlowSessionState[bookingFlows[i]].length; j++) {
      if (!sessionsStates.includes(bookingFlowSessionState[bookingFlows[i]][j])) {
        sessionsStates.push(bookingFlowSessionState[bookingFlows[i]][j]);
      }
    }
  }
  return sessionsStates;
};
