import gql from 'graphql-tag';

const UPDATE_GYM_MANAGER_PASSWORD_MUTATION = gql`
  mutation changeGymManagerPassword($input: ChangeGymManagerPasswordInput!) {
    data: changeGymManagerPassword(input: $input) {
      status
    }
  }
`;

export const Update = (introspection: any) => {
  return (params: any) => {
    if (params.data.input && params.data.input.newPassword) {
      return {
        query: UPDATE_GYM_MANAGER_PASSWORD_MUTATION,
        variables: params.data,
        parseResponse: (resp: any) => {
          if (resp.data.data.status) {
            return { data: { id: 'someID' } }; // we need to return some id, so we return "someID" lol
          }
        },
      };
    }
  };
};
