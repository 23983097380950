import gql from 'graphql-tag';

import { buildQuery } from 'ra-data-graphql-simple';

const GET_LIST_QUERY = gql`
  query allPassValidations(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: PassValidationFilter
  ) {
    items: allPassValidations(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      createdAt
      type
    }
    total: _allPassValidationsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`;

export const GetList = (introspection: any) => {
  const builtQuery = buildQuery(introspection);
  return (params: any) => {
    return {
      ...builtQuery('GET_LIST', 'PassValidation', params),
      query: GET_LIST_QUERY,
    };
  };
};

export const GetMany = (introspection: any) => {
  const builtQuery = buildQuery(introspection);
  return (params: any) => {
    return {
      ...builtQuery('GET_MANY', 'PassValidation', params),
      query: GET_LIST_QUERY,
    };
  };
};

export const GetManyReference = (introspection: any) => {
  return (params: any) => {
    if (params.source === 'pass') {
      params.filter[`passId`] = params.id;
    }

    const preparedVariables: any = {
      // We need to set the page to -1 because the Pagination component will start at 1 and not 0
      page: params.pagination ? params.pagination.page - 1 : undefined,
      perPage: params.pagination ? params.pagination.perPage : undefined,
      sortField: params.sort ? params.sort.field : undefined,
      sortOrder: params.sort ? params.sort.order : undefined,
      filter: { ...params.filter },
    };
    preparedVariables.filter[`${params.target}Id`] =
      params.id.length > 24 ? params.id.substring(0, 24) : params.id;

    return {
      query: GET_LIST_QUERY,
      variables: preparedVariables,
      parseResponse: (resp: any) => {
        if (!resp || !resp.data || !resp.data.items) return resp;

        return {
          data: resp.data.items.map((validation: any, index: number) => {
            return {
              id: index,
              ...validation,
            };
          }),
          total: resp.data.total.count,
        };
      },
    };
  };
};
