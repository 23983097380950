import gymManagers from './gymManagers';
import passwordResets from './passwordResets';
import passes, { passesValidation } from './passes';
import gymUsers from './gymUsers';

import { Menu as MenuType } from '../@types/common';

export const Menu: MenuType = [passes, gymUsers];

export default {
  gymManagers,
  passwordResets,
  passes,
  passesValidation,
  gymUsers,
};
