import React, { Fragment, useCallback } from 'react';

import { Datagrid, Filter, List } from 'react-admin';

import { TextField, TextInput } from 'react-admin';
import { makeStyles, Modal } from '@material-ui/core';
import { Route, RouteChildrenProps, useHistory } from 'react-router-dom';
import { UserShow } from './GymUserShow';
import { colors } from '../../gymlibTheme';
import { getCurrentGyms, shouldDisplayEmail } from '../../utils';

const gyms = getCurrentGyms();
const displayEmail = shouldDisplayEmail(gyms);

const styles = makeStyles({
  filter: {
    '& p[id$="-helper-text"]': {
      display: 'none',
    },
  },
  root: {
    '& div[data-test="bulk-actions-toolbar"]': {
      backgroundColor: colors.lightGray,
      margin: 10,
      borderRadius: 6,
      boxShadow:
        '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.25) !important',
  },
});

const useFilterStyles = makeStyles({
  form: {
    marginLeft: 16,
  },
});

const GymUserFilter = (props: any) => {
  const classes = styles();
  const gymUserStyles = useFilterStyles();
  return (
    <Filter classes={gymUserStyles} {...props}>
      <TextInput label="q" source="q" alwaysOn resettable className={classes.filter} />
    </Filter>
  );
};
export const GymUserList = ({ ...props }: any) => {
  const classes = styles();
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push('/GymUser');
  }, [history]);

  return (
    <div className={classes.root}>
      <Route path="/GymUser/:id">
        {({ match }: RouteChildrenProps<{ id: string }>) => {
          const isMatch = !!(match && match.params && match.params.id !== 'create');

          return (
            <Fragment>
              <List
                {...props}
                bulkActionButtons={false}
                filters={<GymUserFilter />}
                filterDefaultValues={{
                  gymIDs: gyms.map((gym) => gym.id),
                }}
                sort={{ field: 'punchedPasses', order: 'DESC' }}
                actions={null}
                perPage={25}
                title={<></>}
                id="gymuser-list"
              >
                <Datagrid rowClick="edit">
                  {displayEmail && <TextField source="email" />}
                  <TextField
                    label="resources.GymUser.list.firstname"
                    source="firstname"
                    id="gymuser-list-firstname"
                  />
                  <TextField label="resources.GymUser.list.lastname" source="lastname" />
                  <TextField
                    label="resources.GymUser.list.punchedPasses"
                    source="punchedPasses"
                  />
                </Datagrid>
              </List>
              {isMatch && (
                <Modal
                  open={isMatch}
                  onClose={handleClose}
                  className={classes.modal}
                  BackdropProps={{ className: classes.backdrop }}
                >
                  <UserShow
                    onCancel={handleClose}
                    id={(match as any).params.id}
                    {...props}
                  />
                </Modal>
              )}
            </Fragment>
          );
        }}
      </Route>
    </div>
  );
};
