import React from 'react';
import { makeStyles } from '@material-ui/core';
import GpsOffIcon from '@material-ui/icons/GpsOff';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import WatchLateIcon from '@material-ui/icons/WatchLaterOutlined';
import WarningIcon from '@material-ui/icons/ErrorOutline';

import {
  BookingFlow,
  PassActions,
  SessionState,
  RequestAction,
} from '../../@types/common';
import { GenericActionButton } from './GenericActionButton';
import { RequestHandlingButton } from './RequestHandling';

const styles = makeStyles({
  root: {
    marginLeft: 30,
  },
});

export default function ActionButtons(props: any): JSX.Element | null {
  const classes = styles();
  const { data: session } = props;
  if (!session) return null;
  console.log('session', session);
  let button,
    acceptRequest,
    declineRequest,
    lateCancel,
    noShow = null;

  // "SessionStates" driven actions, regardless of the bookingFlow
  if ([SessionState.USABLE, SessionState.BOOKED].includes(session.state)) {
    button = (
      <GenericActionButton
        action={PassActions.GPS_ANOMALY}
        icon={<GpsOffIcon />}
        {...props}
      />
    );
  } else if (session.state === SessionState.REQUEST_SENT) {
    acceptRequest = <RequestHandlingButton action={RequestAction.ACCEPT} {...props} />;
    declineRequest = <RequestHandlingButton action={RequestAction.DECLINE} {...props} />;
  }

  // "BookingFlow & SessionStates" driven actions
  const bookingFlow = session.site.bookingFlow;
  if ([BookingFlow.BOOKING_WITHOUT_ERP_SPECIAL].includes(bookingFlow)) {
    if (session.state === SessionState.CREATED) {
      button = (
        <GenericActionButton
          action={PassActions.VALIDATE_BOOKING}
          icon={<EventAvailableIcon />}
          {...props}
        />
      );
    } else if ([SessionState.USABLE, SessionState.BOOKED].includes(session.state)) {
      lateCancel = (
        <GenericActionButton
          action={PassActions.LATE_CANCELLED}
          icon={<WatchLateIcon />}
          {...props}
        />
      );
      noShow = (
        <GenericActionButton
          action={PassActions.NO_SHOW}
          icon={<WarningIcon />}
          {...props}
        />
      );
    }
  } else if (bookingFlow === BookingFlow.BOOKING_WITH_ERP) {
    if ([SessionState.USABLE, SessionState.BOOKED].includes(session.state)) {
      lateCancel = (
        <GenericActionButton
          action={PassActions.LATE_CANCELLED}
          icon={<WatchLateIcon />}
          {...props}
        />
      );
      noShow = (
        <GenericActionButton
          action={PassActions.NO_SHOW}
          icon={<WarningIcon />}
          {...props}
        />
      );
    }
  }

  if (!noShow && !lateCancel && !button && !acceptRequest && !declineRequest) return null;

  return (
    <div className={classes.root}>
      {declineRequest}
      {acceptRequest}
      {noShow}
      {lateCancel}
      {button}
    </div>
  );
}
