import { CircularProgress, IconButton, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classnames from 'classnames';
import React, { useEffect } from 'react';
import {
  Datagrid,
  DateField,
  FunctionField,
  Link as AdminLink,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useMutation,
  useNotify,
  useShowController,
  useTranslate,
} from 'react-admin';
import { BookingFlow, PassActions, SessionState } from '../../@types/common';
import { colors } from '../../gymlibTheme';
import { getCurrentGyms, shouldDisplayEmail } from '../../utils';
import ActionButtons from './ActionButtons';

const displayEmail = shouldDisplayEmail(getCurrentGyms());

const useStyles = makeStyles({
  icon: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  bottomBar: {
    backgroundColor: colors.lightGray,
    display: 'flex',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 15,
  },
  bottomBarSpecial: {
    backgroundColor: colors.yellow,
    color: colors.darkYellow,
    display: 'flex',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 15,
  },
});

export const PassShow = (props: any) => {
  const translate = useTranslate();
  const controllerProps = useShowController(props);
  const styles = useStyles();
  const notify = useNotify();

  const [syncErpStatus, { data, loading, error }] = useMutation({
    type: 'update',
    resource: 'Pass',
    payload: {
      action: PassActions.SYNC_ERP_STATUS,
      data: { sessionIDs: [props.id] },
    },
  });

  if (data) controllerProps.record.state = data.sessions[0].state;
  if (error) notify(`resources.Pass.actions.syncErpStatus.error`, 'warning');

  useEffect(() => {
    if (
      controllerProps.record?.state === SessionState.CREDIT_TRANSFERED &&
      controllerProps.record?.site.bookingFlow === BookingFlow.BOOKING_WITH_ERP
    ) {
      syncErpStatus();
    }
  }, [controllerProps.record, syncErpStatus]);

  return (
    <>
      <Show {...props} title={<></>} actions={<></>}>
        <TabbedShowLayout id="pass-details">
          <Tab
            label="resources.Pass.tabs.informations"
            id="pass-details-info-tab"
            path="details"
          >
            <ReferenceField
              label="resources.Pass.fields.userName"
              source="gymUser.id"
              reference="GymUser"
              allowEmpty={false}
              resource="Pass"
              link={false}
            >
              <FunctionField
                render={(record: any) => {
                  return (
                    <AdminLink to={`/GymUser/${record.id}`}>
                      <Typography id="pass-details-username">
                        {record.firstname + ' ' + record.lastname}
                      </Typography>
                    </AdminLink>
                  );
                }}
              />
            </ReferenceField>
            {displayEmail && (
              <TextField label="resources.Pass.fields.userEmail" source="gymUser.email" />
            )}
            {controllerProps.record &&
              controllerProps.record.penaltyDate &&
              !controllerProps.record.lastCheckInDate && (
                <DateField source="penaltyDate" showTime />
              )}
            {controllerProps.record && controllerProps.record.startDate && (
              <DateField source="startDate" id="pass-details-expiration-date" />
            )}
            {controllerProps.record && controllerProps.record.lastCheckInDate && (
              <DateField source="lastCheckInDate" showTime />
            )}
            {controllerProps.record && controllerProps.record.expirationDate && (
              <DateField source="expirationDate" id="pass-details-expiration-date" />
            )}
            {controllerProps.record && controllerProps.record.reference && (
              <TextField label="resources.Pass.fields.code" source="reference" />
            )}
          </Tab>
          {controllerProps.record && controllerProps.record.lastCheckInDate && (
            <Tab
              label="resources.Pass.tabs.punchHistory"
              id="pass-details-validations-tab"
              path="validations"
            >
              <ReferenceManyField
                pagination={<Pagination />}
                label=""
                reference="PassValidation"
                target="pass"
                sort={{ field: 'createdAt', order: 'DESC' }}
                perPage={10}
              >
                <Datagrid id="pass-details-punchtype">
                  <DateField
                    label="resources.Pass.fields.punchDate"
                    showTime
                    source="createdAt"
                  />
                  <FunctionField
                    label="resources.Pass.fields.punchType.label"
                    id="pass-details-punchtype-detail"
                    render={(record: any) =>
                      translate(
                        'resources.Pass.fields.punchType.type.' +
                          ((record && record.type) || 'unknown')
                      )
                    }
                  />
                </Datagrid>
              </ReferenceManyField>
            </Tab>
          )}
          <IconButton
            className={styles.icon}
            onClick={props.onCancel}
            id="pass-details-close"
          >
            <CloseIcon />
          </IconButton>
        </TabbedShowLayout>
      </Show>
      {controllerProps.record && (
        <div
          className={classnames({
            [styles.bottomBar]:
              controllerProps.record.state !== SessionState.REQUEST_SENT,
            [styles.bottomBarSpecial]:
              controllerProps.record.state === SessionState.REQUEST_SENT,
          })}
        >
          <Typography component="caption" variant="body1" id="pass-details-state-field">
            {translate(
              'resources.Pass.fields.passStatus.' +
                (controllerProps.record.state || 'unknown')
            )}
          </Typography>
          {loading && <CircularProgress />}
          <ActionButtons data={controllerProps.record} />
        </div>
      )}
    </>
  );
};
