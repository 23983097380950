import gql from 'graphql-tag';
import { sessionIDToUsableID, usableIDToSessionID } from '../../../utils';
import {
  GYM_DATA_FRAGMENT,
  PASS_DATA_IN_APP_BOOKING_FRAGMENT,
  PASS_DATA_SESSION_WITHOUT_ERP_REGULAR_FRAGMENT,
  PASS_DATA_SESSION_WITHOUT_ERP_SPECIAL_FRAGMENT,
  PASS_DATA_SESSION_OPEN_ACCESS_FRAGMENT,
} from './Fragments';

const GET_ONE_QUERY = gql`
  ${GYM_DATA_FRAGMENT}
  ${PASS_DATA_IN_APP_BOOKING_FRAGMENT}
  ${PASS_DATA_SESSION_WITHOUT_ERP_REGULAR_FRAGMENT}
  ${PASS_DATA_SESSION_WITHOUT_ERP_SPECIAL_FRAGMENT}
  ${PASS_DATA_SESSION_OPEN_ACCESS_FRAGMENT}

  query session($id: ID!) {
    data: session(id: $id) {
      id
      creationDate
      lastCheckInDate
      gymUser {
        id
        email
        firstname
        lastname
      }
      state
      site {
        ...GymData
      }
      ...PassDataInAppBooking
      ...PassDataSessionWithoutERPRegular
      ...PassDataSessionWithoutERPSpecial
      ...PassDataSessionOpenAccess
    }
  }
`;

export const GetOne = () => {
  return (params: any) => {
    const preparedVariables: any = {
      id: usableIDToSessionID(params.id),
    };
    return {
      query: GET_ONE_QUERY,
      variables: preparedVariables,
      parseResponse: (resp: any) => {
        if (!resp.data) return { data: {} };
        const session = resp.data.data;
        const sessionDate =
          session.lastCheckInDate ||
          session.startDate ||
          session.optionalstartDate ||
          null;
        const expirationDate = session.expirationDate || session.endDate || null;
        return {
          data: {
            ...session,
            sessionDate,
            expirationDate,
            id: sessionIDToUsableID(session.id),
          },
        };
      },
    };
  };
};
