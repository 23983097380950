/* eslint-disable camelcase */
import React, { useState } from 'react';
import {
  Button,
  useTranslate,
  useRefresh,
  useMutation,
  useNotify,
  Confirm,
  useDataProvider,
  useUnselectAll,
} from 'react-admin';
import { PassActions } from '../../@types/common';
import { makeStyles } from '@material-ui/core';

const styles = makeStyles({
  actionButton: {
    marginLeft: 10,
    height: 32,
    borderRadius: 40,
  },
});

interface GenericActionButtonProps {
  action: PassActions;
  icon: JSX.Element;
  data: any;
}

interface GenericBulkActionButtonProps {
  blockUser: (param: { done: number; total: number }) => void;
  data: any;
  action: PassActions;
  isUserBlocked: boolean;
  icon: JSX.Element;
}

export const GenericActionButton = (props: GenericActionButtonProps): JSX.Element => {
  const classes = styles();
  const payload = { data: props.data, action: props.action };
  const [open, setOpen] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const [callback, { loading }] = useMutation(
    {
      type: 'update',
      resource: 'Pass',
      payload,
    },
    {
      onSuccess: () => {
        notify(`resources.Pass.actions.${props.action}.success`);
        refresh();
      },
      onFailure: (error: Error) => {
        if (error.message.endsWith('NO_USER_ATTEMPT')) {
          notify(`resources.Pass.actions.${props.action}.noAttemptError`, 'warning');
        } else {
          notify(`resources.Pass.actions.${props.action}.error`, 'warning');
        }
      },
    }
  );

  const closeDialog = () => {
    setOpen(false);
  };

  const confirmDialog = () => {
    callback();
    setOpen(false);
  };

  return (
    <>
      <Button
        label={`resources.Pass.actions.${props.action}.name`}
        onClick={() => setOpen(true)}
        color="primary"
        variant="outlined"
        className={classes.actionButton}
        disabled={loading}
        id={`pass-details-${props.action.toLowerCase()}-button`}
      >
        {props.icon}
      </Button>
      <Confirm
        isOpen={open}
        title={translate('resources.Pass.actions.modal.title')}
        content={translate(`resources.Pass.actions.${props.action}.modalContent`)}
        confirm={translate('resources.Pass.actions.modal.confirm')}
        cancel={translate('resources.Pass.actions.modal.cancel')}
        onConfirm={confirmDialog}
        onClose={closeDialog}
      />
    </>
  );
};

export const GenericBulkActionButton = (
  props: GenericBulkActionButtonProps
): JSX.Element => {
  const classes = styles();
  const [openDialog, setOpenDialog] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const unselectAll = useUnselectAll();
  const callback = async () => {
    let errors = 0;
    props.blockUser({
      done: 0,
      total: props.data.length,
    });
    for (let i = 0; i < props.data.length; i++) {
      props.blockUser({ done: i, total: props.data.length });
      try {
        await dataProvider.update('Pass', { action: props.action, data: props.data[i] });
      } catch (e) {
        errors++;
      }
      props.blockUser({ done: i + 1, total: props.data.length });
    }
    if (errors === props.data.length) {
      notify(`resources.Pass.actions.${props.action}Bulk.error`, 'warning', {
        smart_count: props.data.length,
      });
    } else {
      notify(`resources.Pass.actions.${props.action}Bulk.success`, 'info', {
        successCount: props.data.length - errors,
        failsCount: errors,
        smart_count: props.data.length,
      });
      unselectAll('Pass');
    }
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const confirmDialog = async () => {
    setOpenDialog(false);
    await callback();
  };

  return (
    <>
      <Button
        label={translate(`resources.Pass.actions.${props.action}Bulk.name`, {
          smart_count: props.data.length,
        })}
        onClick={() => setOpenDialog(true)}
        color="primary"
        variant="outlined"
        className={classes.actionButton}
        disabled={props.isUserBlocked}
      >
        {props.icon}
      </Button>
      <Confirm
        isOpen={openDialog}
        title={translate('resources.Pass.actions.modal.title')}
        content={translate(`resources.Pass.actions.${props.action}Bulk.modalContent`, {
          smart_count: props.data.length,
        })}
        confirm={translate('resources.Pass.actions.modal.confirm')}
        cancel={translate('resources.Pass.actions.modal.cancel')}
        onConfirm={confirmDialog}
        onClose={closeDialog}
      />
    </>
  );
};
