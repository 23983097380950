import { IResourceExport } from '../../@types/dataProvider';

import gymManagerProvider from './dataProvider';

export default {
  name: 'GymManager',
  dataProvider: {
    provider: gymManagerProvider,
  },
} as IResourceExport;
