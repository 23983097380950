import React from 'react';
import {
  Datagrid,
  Show,
  Tab,
  TabbedShowLayout,
  Pagination,
  DateField,
  ImageField,
  ReferenceManyField,
  TextField,
  useTranslate,
  useShowController,
} from 'react-admin';

import { makeStyles, IconButton } from '@material-ui/core';
import { SessionState } from '../../@types/common';
import CloseIcon from '@material-ui/icons/Close';
import classnames from 'classnames';
import { colors } from '../../gymlibTheme';
import { getCurrentGyms, shouldDisplayEmail } from '../../utils';

const displayEmail = shouldDisplayEmail(getCurrentGyms());

const styles = makeStyles({
  root: {
    outline: 'none',
    borderRadius: 4,
    minWidth: 700,
    minHeight: 520,
    backgroundColor: 'white',
    boxShadow:
      '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
  },
  infoData: {
    display: 'flex',
  },
  icon: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  statusField: {
    fontSize: 12,
  },
  statusFieldYellow: {
    fontSize: 12,
    backgroundColor: colors.yellow,
    color: colors.darkYellow,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 40,
    marginLeft: -8,
  },
});

export const UserShow = (props: any): JSX.Element => {
  const controllerProps = useShowController(props);
  const translate = useTranslate();
  const classes = styles();

  const StateField = (props: any) =>
    (props.record && (
      <div
        className={classnames({
          [classes.statusField]: props.record.state !== SessionState.REQUEST_SENT,
          [classes.statusFieldYellow]: props.record.state === SessionState.REQUEST_SENT,
        })}
      >
        <span>
          {translate(
            'resources.Pass.fields.passStatus.' +
              ((props.record && props.record.state) || 'unknown')
          )}
        </span>
      </div>
    )) ||
    null;

  return (
    <Show {...props} className={classes.root} actions={<></>} title={<></>}>
      <TabbedShowLayout id="gymuser-details">
        <Tab label="resources.GymUser.tabs.infos" id="gymuser-details-info-tab">
          {controllerProps.record && controllerProps.record.picture && (
            <ImageField source="picture" />
          )}
          <TextField
            label="resources.GymUser.fields.firstname"
            source="firstname"
            className={classes.infoData}
            id="gymuser-details-firstname"
          />
          <TextField
            label="resources.GymUser.fields.lastname"
            source="lastname"
            className={classes.infoData}
          />
          <DateField
            label="resources.GymUser.fields.birthday"
            source="birthday"
            className={classes.infoData}
          />
          {displayEmail && <TextField source="email" className={classes.infoData} />}
        </Tab>
        <Tab label="resources.GymUser.tabs.passes" id="gymuser-details-passes-tab">
          <ReferenceManyField
            pagination={<Pagination perPage={5} />}
            label=""
            reference="Pass"
            target="user"
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={5}
          >
            <Datagrid rowClick={(id: any) => `/Pass/${id}/details`}>
              <TextField source="site.name" label="resources.Pass.fields.gymName" />
              <DateField
                source="lastCheckInDate"
                showTime
                label="resources.Pass.fields.lastValidationDate"
              />
              <DateField
                source="expirationDate"
                showTime
                id="gymuser-details-pass-expiration-date"
              />
              <StateField label="resources.Pass.fields.status" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <IconButton className={classes.icon} onClick={props.onCancel}>
          <CloseIcon />
        </IconButton>
      </TabbedShowLayout>
    </Show>
  );
};
