import React from 'react';
import AppBar from './AppBar';

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, ThemeProvider } from '@material-ui/core';
import {
  Notification,
  Sidebar,
  setSidebarVisibility,
  ComponentPropType,
} from 'react-admin';
import gymlibTheme from '../gymlibTheme';
import Menu from './Menu';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    minHeight: '100vh',
    backgroundColor: 'white',
    position: 'relative',
  },
  appFrame: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
  },
  contentWithSidebar: {
    display: 'flex',
    flexGrow: 1,
    backgroundColor: gymlibTheme.palette.secondary.main,
  },
  content: {
    backgroundColor: 'white',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2,
    marginTop: 'calc(4em - 2px)',
    boxShadow:
      '1px 6px 6px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    borderRadius: 4,
    maxWidth: 1160,
  },
  menu: {
    marginTop: 52,
  },
});

const MyLayout = ({ children, logout, title, ...rest }: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen);

  useEffect(() => {
    dispatch(setSidebarVisibility(true));
  }, [dispatch]);

  return (
    <ThemeProvider theme={gymlibTheme}>
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <AppBar title={title} open={open} logout={logout} />
          <main className={classes.contentWithSidebar}>
            <Sidebar className={classes.menu}>
              <Menu logout={logout} {...rest} />
            </Sidebar>
            <div className={classes.content}>{children}</div>
          </main>
          <Notification />
        </div>
      </div>
    </ThemeProvider>
  );
};

MyLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  logout: ComponentPropType,
  title: PropTypes.string.isRequired,
};

export default MyLayout;
