/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { RequestAction } from '../../@types/common';
import { useLocation, Link } from 'react-router-dom';
import {
  makeStyles,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  Checkbox,
  ListItemText,
  Zoom,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import { colors } from '../../gymlibTheme';
import {
  Button,
  useTranslate,
  useDataProvider,
  useNotify,
  useMutation,
  Notification,
} from 'react-admin';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Info from '@material-ui/icons/Info';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

export enum DeclineReason {
  CLIENT_UNEXPECTED = 'CLIENT_UNEXPECTED',
  CLASS_UNAVAILABLE = 'CLASS_UNAVAILABLE',
  // NO_ACCOUNT = 'NO_ACCOUNT',
  OTHER = 'OTHER',
}

const styles = makeStyles({
  acceptRequestButton: {
    '&:hover': {
      backgroundColor: colors.primary,
    },
    backgroundColor: colors.primary,
    fontWeight: 600,
    color: 'white',
    marginLeft: 10,
    height: 32,
    borderRadius: 40,
    paddingLeft: 25,
    paddingRight: 25,
    '& span': {
      paddingLeft: 0,
    },
  },
  declineRequestButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    fontWeight: 600,
    marginLeft: 10,
    height: 32,
    borderRadius: 40,
    paddingLeft: 25,
    paddingRight: 25,
    '& span': {
      paddingLeft: 0,
    },
  },
  root: {
    display: 'flex',
    maxWidth: 700,
    flex: 1,
    flexDirection: 'column',
  },
  headerNContent: {
    padding: 40,
    paddingBottom: 20,
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
  bodyToTheLeft: {
    paddingTop: 8,
    paddingRight: 72,
    paddingLeft: 28,
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    textAlign: 'center',
    flexDirection: 'column',
  },
  body: {
    paddingTop: 8,
    paddingRight: 28,
    paddingLeft: 28,
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    textAlign: 'center',
    flexDirection: 'column',
  },
  iconContainer: {
    padding: 8,
    alignSelf: 'flex-start',
  },
  icon: {
    height: 28,
    width: 28,
  },
  acceptTitle: {
    color: colors.black,
    fontSize: 24,
    marginBottom: 24,
  },
  declineTitle: {
    color: colors.error,
    fontSize: 24,
    marginBottom: 24,
  },
  select: {
    width: 400,
    alignSelf: 'center',
    marginBottom: 24,
  },
  emailListContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emailList: {
    maxHeight: 150,
    overflow: 'auto',
    width: 'fit-content',
    paddingRight: 30,
    paddingLeft: 30,
    alignSelf: 'center',
    color: colors.darkGray,
  },
  bottom: {
    borderTopColor: colors.midGray,
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    padding: 20,
    paddingTop: 10,
    height: 120,
  },
  tooltipContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    marginRight: 8,
  },
  tooltipIcon: {
    height: 15,
    width: 15,
    color: colors.midGray,
    marginLeft: 12,
  },
  acceptButton: {
    '&:hover': {
      backgroundColor: colors.primary,
    },
    backgroundColor: colors.primary,
    fontWeight: 600,
    color: 'white',
    height: 32,
    borderRadius: 40,
    paddingLeft: 75,
    paddingRight: 75,
    '& span': {
      paddingLeft: 0,
    },
    '&:disabled': {
      backgroundColor: colors.midGray,
    },
  },
  cancelButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    fontWeight: 600,
    marginLeft: 10,
    height: 32,
    borderRadius: 40,
    paddingLeft: 75,
    paddingRight: 75,
    '& span': {
      paddingLeft: 0,
    },
  },
});

interface RequestHandlingButtonProps {
  action: RequestAction;
  data: any;
}

interface RequestHandlingBulkButtonProps {
  action: RequestAction;
  data: any;
  onClick: () => void;
}

interface RequestHandlingModalContentProps {
  action: RequestAction;
  sessionIDs: [string];
  displayBack?: boolean;
  displayCancel?: boolean;
  cancelAction?: () => void;
  onAction: () => void;
}

export const RequestHandlingButton = (props: RequestHandlingButtonProps): JSX.Element => {
  const classes = styles();
  const location = useLocation();

  return (
    <Button
      label={`resources.Pass.actions.${props.action}.name`}
      component={Link}
      to={{ pathname: `${location.pathname}/${props.action}` }}
      className={classnames({
        [classes.acceptRequestButton]: props.action === RequestAction.ACCEPT,
        [classes.declineRequestButton]: props.action === RequestAction.DECLINE,
      })}
      id={`pass-details-${props.action.toLowerCase()}`}
    />
  );
};

export const RequestHandlingBulkButton = (
  props: RequestHandlingBulkButtonProps
): JSX.Element => {
  const classes = styles();
  const translate = useTranslate();

  return (
    <Button
      label={translate(`resources.Pass.actions.${props.action}Bulk.name`, {
        smart_count: props.data.length,
      })}
      onClick={props.onClick}
      className={classnames({
        [classes.acceptRequestButton]: props.action === RequestAction.ACCEPT,
        [classes.declineRequestButton]: props.action === RequestAction.DECLINE,
      })}
    />
  );
};

export const RequestHandlingModalContent = (
  props: RequestHandlingModalContentProps
): JSX.Element => {
  const classes = styles();
  const translate = useTranslate();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = React.useState(false);
  const [reason, setReason] = React.useState('');
  const history = useHistory();

  useEffect(() => {
    dataProvider
      .getList('Pass', {
        filter: { ids: props.sessionIDs },
      })
      .then((res: any) => {
        setData(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        notify('Une erreur est survenue', 'error');
      });
    // eslint-disable-next-line
  }, []);

  const [mutate, { loading: mutationLoading }] = useMutation();
  const approve = () =>
    mutate(
      {
        type: 'update',
        resource: 'Pass',
        payload: {
          data: {
            ids: props.sessionIDs,
            declineReason: reason || undefined,
            action: props.action,
          },
        },
      },
      {
        onSuccess: () => {
          props.onAction();
          notify(`resources.Pass.actions.${props.action}Bulk.success`, 'info', {
            smart_count: props.sessionIDs.length,
          });
        },
        onFailure: () => {
          props.onAction();
          notify(`resources.Pass.actions.${props.action}Bulk.error`, 'warning', {
            smart_count: props.sessionIDs.length,
          });
        },
      }
    );

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setReason(event.target.value as string);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <div className={classes.root}>
      <div className={classes.headerNContent}>
        {props.displayBack && (
          <IconButton className={classes.iconContainer} onClick={() => history.goBack()}>
            <ArrowBack className={classes.icon} />
          </IconButton>
        )}
        <div
          className={classnames({
            [classes.bodyToTheLeft]: props.displayBack,
            [classes.body]: !props.displayBack,
          })}
        >
          <Typography
            className={classnames({
              [classes.acceptTitle]: props.action === RequestAction.ACCEPT,
              [classes.declineTitle]: props.action === RequestAction.DECLINE,
            })}
          >
            {translate(`resources.Pass.actions.${props.action}.title`)}
          </Typography>
          {props.action === RequestAction.DECLINE && (
            <FormControl variant="filled" className={classes.select}>
              <InputLabel htmlFor="decline_reason_select">
                {translate(
                  `resources.Pass.actions.${RequestAction.DECLINE}.selectPlaceholder`
                )}
              </InputLabel>
              <Select
                name="decline_reason_select"
                value={reason}
                onChange={handleSelectChange}
                id="pass-details-decline-request-select"
              >
                {Object.values(DeclineReason).map((reason) => (
                  <MenuItem value={reason} key={reason}>
                    {translate(
                      `resources.Pass.actions.${RequestAction.DECLINE}.reason.${DeclineReason[reason]}`
                    )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <div className={classes.emailListContainer}>
            <List className={classes.emailList}>
              {!loading &&
                data.map((session: any, index: number) => (
                  <ListItemText
                    key={session.id}
                    primary={session.gymUser.email}
                    id={`pass-details-request-email-${index.toString()}`}
                  />
                ))}
              {loading && <CircularProgress />}
            </List>
          </div>
        </div>
      </div>
      <div className={classes.bottom}>
        {props.action === RequestAction.DECLINE && (
          <div className={classes.tooltipContainer}>
            <Checkbox
              checked={checked}
              color="primary"
              onChange={handleCheckboxChange}
              className={classes.checkbox}
              id="pass-details-request-checkbox"
            />
            <Typography>
              {translate(`resources.Pass.actions.${RequestAction.DECLINE}.checkbox`)}
            </Typography>
            <Tooltip
              TransitionComponent={Zoom}
              placement="top"
              title={translate(`resources.Pass.actions.${RequestAction.DECLINE}.tooltip`)}
            >
              <Info className={classes.tooltipIcon} />
            </Tooltip>
          </div>
        )}
        <Button
          label={translate('resources.Pass.actions.request.send')}
          disabled={
            mutationLoading ||
            loading ||
            (props.action === RequestAction.DECLINE ? !checked || reason === '' : false)
          }
          className={classes.acceptButton}
          onClick={approve}
          id="pass-details-request-button"
        />
        {props.displayCancel && (
          <Button
            label={translate('resources.Pass.actions.request.cancel')}
            onClick={props.cancelAction}
            className={classes.cancelButton}
          />
        )}
      </div>
      <Notification id="snack-bar" autoHideDuration={3000} />
    </div>
  );
};

RequestHandlingModalContent.defaultProps = {
  action: RequestAction.ACCEPT,
  sessionIDs: [],
  displayBack: true,
  displayCancel: false,
  cancelAction: () => null,
  onAction: () => null,
};
