import gql from 'graphql-tag';
import moment from 'moment';
import { SessionState } from '../../../@types/common';
import {
  GYM_DATA_FRAGMENT,
  PASS_DATA_IN_APP_BOOKING_FRAGMENT,
  PASS_DATA_SESSION_WITH_ERP_FRAGMENT,
  PASS_DATA_SESSION_WITHOUT_ERP_REGULAR_FRAGMENT,
  PASS_DATA_SESSION_WITHOUT_ERP_SPECIAL_FRAGMENT,
  PASS_DATA_SESSION_OPEN_ACCESS_FRAGMENT,
} from './Fragments';
import { sessionIDToUsableID, usableIDToSessionID, getCurrentGyms } from '../../../utils';

const GET_LIST_QUERY = gql`
  ${GYM_DATA_FRAGMENT}
  ${PASS_DATA_IN_APP_BOOKING_FRAGMENT}
  ${PASS_DATA_SESSION_WITH_ERP_FRAGMENT}
  ${PASS_DATA_SESSION_WITHOUT_ERP_REGULAR_FRAGMENT}
  ${PASS_DATA_SESSION_WITHOUT_ERP_SPECIAL_FRAGMENT}
  ${PASS_DATA_SESSION_OPEN_ACCESS_FRAGMENT}

  query allSessions(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: SessionFilter
  ) {
    items: allSessions(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      creationDate
      lastCheckInDate
      gymUser {
        id
        email
        firstname
        lastname
      }
      state
      site {
        ...GymData
      }
      ...PassDataInAppBooking
      ...PassDataSessionWithERP
      ...PassDataSessionWithoutERPRegular
      ...PassDataSessionWithoutERPSpecial
      ...PassDataSessionOpenAccess
    }
    total: _allSessionsMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`;

const parseResponse = (resp: any) => {
  return {
    data: resp.data.items.map((session: any) => {
      const sessionDate =
        session.lastCheckInDate || session.startDate || session.optionalstartDate || null;
      const expirationDate = session.expirationDate || session.endDate || null;
      return {
        ...session,
        sessionDate,
        expirationDate,
        id: sessionIDToUsableID(session.id),
      };
    }),
    total: resp.data.total.count,
  };
};

export const GetList = () => {
  return (params: any) => {
    const gyms = getCurrentGyms();

    params.filter = {
      ...params.filter,
      gymIDs: gyms.map((gym) => gym.id),
    };

    if (params.filter.endDate)
      params.filter.endDate = moment(params.filter.endDate).format('YYYY-MM-DD');

    if (params.filter.ids?.length) {
      params.filter.ids = params.filter.ids.map(usableIDToSessionID);
    } else {
      params.filter.ids = undefined;
    }
    if (params.filter.startDate || params.filter.endDate) {
      if (params.filter.state === SessionState.CHECKED_IN) {
        params.filter.dateFilterField = 'lastCheckInDate';
      } else if (
        [SessionState.NO_SHOW, SessionState.LATE_CANCELLED].includes(params.filter.state)
      ) {
        params.filter.dateFilterField = 'updatedAt';
      } else {
        params.filter.dateFilterField = 'startDate';
      }
    }

    const preparedVariables: any = {
      // We need to set the page to -1 because the Pagination component will start at 1 and not 0
      page: params.pagination ? params.pagination.page - 1 : undefined,
      perPage: params.pagination ? params.pagination.perPage : undefined,
      sortField: params.sort ? params.sort.field : undefined,
      sortOrder: params.sort ? params.sort.order : undefined,
      filter: { ...params.filter },
    };

    return {
      query: GET_LIST_QUERY,
      variables: preparedVariables,
      parseResponse,
    };
  };
};

export const GetManyReference = () => {
  return (params: any) => {
    const gyms = getCurrentGyms();

    if (params.source === 'user') {
      params.filter[`userId`] = params.id;
    }

    const preparedVariables: any = {
      // We need to set the page to -1 because the Pagination component will start at 1 and not 0
      page: params.pagination ? params.pagination.page - 1 : undefined,
      perPage: params.pagination ? params.pagination.perPage : undefined,
      sortField: params.sort ? params.sort.field : undefined,
      sortOrder: params.sort ? params.sort.order : undefined,
      filter: { ...params.filter },
    };
    preparedVariables.filter[`${params.target}Id`] = params.id;
    preparedVariables.filter['gymIDs'] = gyms.map((gym) => gym.id);

    return {
      query: GET_LIST_QUERY,
      variables: preparedVariables,
      parseResponse,
    };
  };
};
