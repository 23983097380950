import React, { useState, useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { Admin, Resource, resolveBrowserLocale } from 'react-admin';

import gymlibTheme from './gymlibTheme';
import authProvider from './authProvider/authProvider';
import reloadedDataProvider from './dataProvider/dataProvider';

import resources from './resources';

import { Layout } from './layout';
import { Login } from './layout/login';
import { Dashboard } from './dashboard';

import frenchMessages from 'ra-language-french';
import englishMessages from 'ra-language-english';
import dutchMessages from 'ra-language-dutch';
import gymlibMessages from './i18n';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import customRoutes from './routes';

hotjar.initialize(1642546, 6);
if (window.hj) window.hj('identify', localStorage.getItem('userID'), {});

const messages: any = {
  fr: { ...frenchMessages, ...gymlibMessages.fr },
  en: { ...englishMessages, ...gymlibMessages.en },
  nl: { ...dutchMessages, ...gymlibMessages.nl },
};

const i18nProvider = polyglotI18nProvider(
  (locale) => (messages[locale] ? messages[locale] : messages['fr']),
  resolveBrowserLocale()
);

const App = () => {
  const [dataProvider, setDataProvider] = useState<
    ((type: any, resource: any, params: any) => any) | null
  >(null);

  useEffect(() => {
    reloadedDataProvider(resources).then((dataProvider: any) => {
      setDataProvider(() => dataProvider);
    });
  }, []);

  if (!dataProvider) return <div>{i18nProvider.translate('loading')}</div>;

  return (
    <Admin
      dataProvider={dataProvider}
      customRoutes={customRoutes}
      authProvider={authProvider}
      loginPage={Login}
      dashboard={Dashboard}
      theme={gymlibTheme}
      i18nProvider={i18nProvider}
      layout={Layout}
    >
      {Object.values(resources).map((resource) => {
        if (resource.resources) {
          return (
            <Resource key={resource.name} name={resource.name} {...resource.resources} />
          );
        } else {
          // fake resources
          return <Resource key={resource.name} name={resource.name} />;
        }
      })}
    </Admin>
  );
};

export default App;
