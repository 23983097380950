import gql from 'graphql-tag';

export const GYM_DATA_FRAGMENT = gql`
  fragment GymData on Gym {
    id
    code
    name
    bookingFlow
  }
`;

export const PASS_DATA_IN_APP_BOOKING_FRAGMENT = gql`
  fragment PassDataInAppBooking on InAppBookingSession {
    startDate
    coach
    name
    penaltyDate
  }
`;

export const PASS_DATA_SESSION_WITH_ERP_FRAGMENT = gql`
  fragment PassDataSessionWithERP on SessionWithERP {
    optionalstartDate: startDate
    expirationDate
    penaltyDate
  }
`;

export const PASS_DATA_SESSION_WITHOUT_ERP_REGULAR_FRAGMENT = gql`
  fragment PassDataSessionWithoutERPRegular on SessionWithoutERPRegular {
    optionalstartDate: startDate
    expirationDate
    penaltyDate
  }
`;

export const PASS_DATA_SESSION_WITHOUT_ERP_SPECIAL_FRAGMENT = gql`
  fragment PassDataSessionWithoutERPSpecial on SessionWithoutERPSpecial {
    optionalstartDate: startDate
    expirationDate
    reference
    penaltyDate
    publicPrice
    gymOffer {
      name
    }
  }
`;

export const PASS_DATA_SESSION_OPEN_ACCESS_FRAGMENT = gql`
  fragment PassDataSessionOpenAccess on OpenAccessSession {
    optionalstartDate: startDate
    expirationDate
    reference
  }
`;
