import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS,
} from 'react-admin';
import { managerLogin, managerLogout } from './login';

export default (type: string, params: any) => {
  console.log(type, params);
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    return managerLogin(username, password)
      .then((managerInfo) => {
        console.log('LOGIN', managerInfo);
        const gyms = managerInfo.manager.gyms.map((gym: any) => ({
          id: gym.id,
          name: gym.name,
          bookingFlow: gym.bookingFlow,
          payWithPassCode: gym.payWithPassCode ?? false,
        }));
        localStorage.setItem('logStatus', Math.random().toString(24).slice(2));
        localStorage.setItem(
          'manager',
          JSON.stringify({ email: managerInfo.manager.email, gyms })
        );
        localStorage.setItem(
          'currentGym',
          JSON.stringify(gyms.sort((a, b) => (a.name < b.name ? -1 : 1))[0])
        );
        localStorage.setItem('managerID', managerInfo.manager.id);
        window.hj('managerID', managerInfo.manager.id, {});
      })
      .catch((e: Error) => {
        if (e.message.endsWith('Invalid credentials')) {
          e.message = 'Identifiants incorrects';
        } else {
          e.message = 'Une erreur est apparue, veuillez réessayer';
        }
        throw e;
      });
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    console.log('LOGIN OUT');
    localStorage.clear();

    try {
      managerLogout();
    } catch (e) {}

    return Promise.resolve();
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    console.log('LOG ERROR', JSON.stringify(params));
    if (params instanceof Error) {
      if (params.message === 'USER_NOT_AUTHENTICATED') {
        localStorage.clear();
        return Promise.reject(`Could no authenticate with username`);
      }
    }
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.clear();
      return Promise.reject(`Could no authenticate with username: ${status}`);
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    console.log(type, params);
    if (!localStorage.getItem('logStatus')) {
      console.log('NOT CONNECTED');
      return Promise.reject();
    } else {
      return Promise.resolve();
    }
  }
  if (type === AUTH_GET_PERMISSIONS) {
    return Promise.resolve();
  }
  return Promise.reject('Unknown method');
};
