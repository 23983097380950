import { createMuiTheme } from '@material-ui/core/styles';

export const colors = {
  primary: '#3C0CBF',
  secondary: '#E8E0FC',
  error: '#E43B50',
  success: '#48D768',
  lightGray: '#F1F1F3',
  black: '#1B1B1D',
  darkGray: '#525268',
  midGray: '#C1C1C4',
  yellow: '#FAE8B6',
  darkYellow: '#634905',
};

export default createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: colors.error,
    },
    success: {
      main: colors.success,
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
  overrides: {
    MuiTableCell: {
      sizeSmall: {
        color: colors.black,
        paddingTop: 10,
        paddingBottom: 10,
      },
      head: {
        fontWeight: 600,
      },
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: colors.primary,
        borderRadius: 200,
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    MuiDrawer: {
      root: {
        backgroundColor: colors.secondary,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: colors.success,
      },
    },
    MuiFilledInput: {
      underline: {
        '&::before': {
          borderBottom: 'none',
        },
        borderRadius: 4,
      },
    },
  },
});
