import React, { FunctionComponent, useState, useEffect } from 'react';
import {
  makeStyles,
  Typography,
  TextField,
  Button,
  CssBaseline,
  Card,
  Container,
  CircularProgress,
  ThemeProvider,
} from '@material-ui/core';

import {
  useNotify,
  useDataProvider,
  useTranslate,
  useRedirect,
  Notification,
} from 'react-admin';
import { sleep } from '../utils';
import { LocalGym } from '../@types/common';
import { Field, Form } from 'react-final-form';
import { managerLogin } from '../authProvider/login';
import gymlibTheme from '../gymlibTheme';

const PASSWORD_REGEX = /(?=.*[0-9])(?=.*[a-záàâäãåçéèêëíìîïñóòôöõúùûüýÿæœ])(?=.*[A-ZÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ]).{8,}/;

interface ChangePasswordProps {
  match: any;
}

const styles = makeStyles({
  withShadow: {
    boxShadow:
      '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
  },
  paper: {
    margin: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    width: '100%',
    marginTop: '15px',
  },
  submit: {
    marginTop: '30px',
  },
  formControl: {
    display: 'flex',
  },
  logo: {
    display: 'block',
    margin: 'auto',
    height: '45px',
  },
  logoContainer: {
    height: '15vh',
    minHeight: '100px',
    display: 'flex',
  },
  icon: {
    marginRight: '5px',
  },
});

interface FormData {
  password1: string;
  password2: string;
}

const ChangePassword: FunctionComponent<ChangePasswordProps> = ({
  match,
}: ChangePasswordProps) => {
  const classes = styles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const translate = useTranslate();
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [canUpdatePassword, setCanUpdatePassword] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isPasswordInitialization, setIsPasswordInitialization] = useState(false);

  useEffect(() => {
    dataProvider
      .getOne('PasswordReset', {
        id: match.params.id,
      })
      .then((res: any) => {
        if (res.data.status === 'PENDING' && new Date(res.data.expiredAt) > new Date()) {
          setIsPasswordInitialization(res.data.isPasswordInitialization);
          setEmail(res.data.sourceEmail);
          setCanUpdatePassword(true);
        } else {
          setCanUpdatePassword(false);
        }
        setFirstLoad(false);
      })
      .catch(() => {
        notify('pages.passwordReset.expiredLink', 'warning');
        setCanUpdatePassword(false);
        setFirstLoad(false);
      });
    // eslint-disable-next-line
  }, []);

  const submit = async (values: FormData) => {
    setLoading(true);
    try {
      await dataProvider.update('GymManager', {
        data: {
          input: {
            passwordResetID: match.params.id,
            newPassword: values.password1,
          },
        },
      });
      notify('pages.passwordReset.passwordChanged', 'info');
      const managerInfo = await managerLogin(email, values.password1);
      const gyms: LocalGym[] = managerInfo.manager.gyms.map((gym: any) => ({
        id: gym.id,
        name: gym.name,
        bookingFlow: gym.bookingFlow,
      }));
      localStorage.setItem('logStatus', Math.random().toString(24).slice(2));
      localStorage.setItem(
        'manager',
        JSON.stringify({ email: managerInfo.manager.email, gyms })
      );
      localStorage.setItem(
        'currentGym',
        JSON.stringify(
          gyms.sort((a: LocalGym, b: LocalGym) => (a.name < b.name ? -1 : 1))[0]
        )
      );
      await sleep(3000);
      redirect('/Pass');
    } catch (e) {
      await sleep(500);
      setLoading(false);
      if (e.message.endsWith('InvalidPasswordReset')) {
        notify('pages.passwordReset.invalidURL', 'warning');
      } else if (e.message.endsWith('ExpiredPasswordReset')) {
        notify('pages.passwordReset.expiredURL', 'warning');
      } else if (e.message.endsWith('PasswordTooWeakException')) {
        notify('pages.passwordReset.tooWeakPasswordError', 'warning');
      } else if (e.message.endsWith('ServerError')) {
        notify('pages.passwordReset.serverError', 'warning');
      } else {
        notify('pages.passwordReset.classicError', 'warning');
      }
    }
  };

  return (
    <ThemeProvider theme={gymlibTheme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.logoContainer}>
          <img className={classes.logo} alt="" src={require('../images/logo.svg')} />
        </div>
        <Card className={classes.withShadow}>
          <div className={classes.paper}>
            {canUpdatePassword && !firstLoad && (
              <>
                <Typography component="h1" variant="h5">
                  {isPasswordInitialization
                    ? translate('pages.passwordInit.title')
                    : translate('pages.passwordReset.title')}
                </Typography>
                {email !== '' && (
                  <Typography component="caption" variant="caption">
                    {'(' + email + ')'}
                  </Typography>
                )}
                <Form
                  onSubmit={submit}
                  validate={(values) => {
                    const errors = { password1: '', password2: '' };
                    if (!values.password1) {
                      errors.password1 = translate('ra.validation.required');
                      return errors;
                    }
                    if (values.password1 && !PASSWORD_REGEX.test(values.password1)) {
                      errors.password1 = translate(
                        'pages.passwordReset.tooWeakPasswordInput'
                      );
                      return errors;
                    }
                    if (!values.password2) {
                      errors.password2 = translate('ra.validation.required');
                      return errors;
                    }
                    if (
                      values.password1 &&
                      values.password2 &&
                      values.password1 !== values.password2
                    ) {
                      errors.password2 = translate(
                        'pages.passwordReset.notSamePasswordInput'
                      );
                      return errors;
                    }
                    if (values.password2 && !PASSWORD_REGEX.test(values.password2)) {
                      errors.password2 = translate(
                        'pages.passwordReset.tooWeakPasswordInput'
                      );
                      return errors;
                    }
                    return {};
                  }}
                >
                  {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} className={classes.form}>
                      <Field
                        id="password-1"
                        label="Mot de passe"
                        autoComplete="password-1"
                        name="password1"
                        type="password"
                        disabled={loading}
                      >
                        {(field) => (
                          <TextField
                            error={!!(field.meta.touched && field.meta.error)}
                            helperText={field.meta.touched && field.meta.error}
                            autoFocus
                            margin="normal"
                            required
                            fullWidth
                            variant="outlined"
                            {...field.input}
                            {...field}
                          />
                        )}
                      </Field>
                      <Field
                        id="password-2"
                        label={translate('pages.passwordReset.retypePassword')}
                        autoComplete="password-2"
                        name="password2"
                        type="password"
                        disabled={loading}
                      >
                        {(field) => (
                          <TextField
                            error={!!(field.meta.touched && field.meta.error)}
                            helperText={field.meta.touched && field.meta.error}
                            margin="normal"
                            required
                            fullWidth
                            variant="outlined"
                            {...field.input}
                            {...field}
                          />
                        )}
                      </Field>
                      <Button
                        type="submit"
                        fullWidth
                        color="primary"
                        variant="contained"
                        id="submit-button"
                        className={classes.submit}
                        disabled={loading}
                      >
                        {loading && (
                          <CircularProgress
                            className={classes.icon}
                            size={18}
                            thickness={2}
                          />
                        )}
                        {isPasswordInitialization
                          ? translate('pages.passwordInit.button')
                          : translate('pages.passwordReset.button')}
                      </Button>
                    </form>
                  )}
                </Form>
              </>
            )}
            {!canUpdatePassword && (
              <Typography component="h1" variant="h5">
                {firstLoad
                  ? translate('pages.passwordReset.loading')
                  : translate('pages.passwordReset.wrongLink')}
              </Typography>
            )}
          </div>
        </Card>
        <Notification id="snack-bar" autoHideDuration={3000} />
      </Container>
    </ThemeProvider>
  );
};
export default ChangePassword;
