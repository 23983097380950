import gql from 'graphql-tag';
import { PassActions, RequestAction, requestType } from '../../../@types/common';
import { sessionIDToUsableID, usableIDToSessionID } from '../../../utils';
import {
  GYM_DATA_FRAGMENT,
  PASS_DATA_IN_APP_BOOKING_FRAGMENT,
  PASS_DATA_SESSION_OPEN_ACCESS_FRAGMENT,
  PASS_DATA_SESSION_WITHOUT_ERP_REGULAR_FRAGMENT,
  PASS_DATA_SESSION_WITHOUT_ERP_SPECIAL_FRAGMENT,
  PASS_DATA_SESSION_WITH_ERP_FRAGMENT,
} from './Fragments';

const DECLARE_GPS_ANOMALY_MUTATION = gql`
  ${GYM_DATA_FRAGMENT}
  ${PASS_DATA_IN_APP_BOOKING_FRAGMENT}
  ${PASS_DATA_SESSION_WITH_ERP_FRAGMENT}
  ${PASS_DATA_SESSION_WITHOUT_ERP_REGULAR_FRAGMENT}
  ${PASS_DATA_SESSION_WITHOUT_ERP_SPECIAL_FRAGMENT}
  ${PASS_DATA_SESSION_OPEN_ACCESS_FRAGMENT}

  mutation DP_DeclareGPSAnomaly($input: DP_DeclareGPSAnomalyInput!) {
    DP_DeclareGPSAnomaly(input: $input) {
      id
      creationDate
      lastCheckInDate
      gymUser {
        id
        email
        firstname
        lastname
      }
      state
      site {
        ...GymData
      }
      ...PassDataInAppBooking
      ...PassDataSessionWithERP
      ...PassDataSessionWithoutERPRegular
      ...PassDataSessionWithoutERPSpecial
      ...PassDataSessionOpenAccess
    }
  }
`;

const TRANSFER_PASS_MUTATION = gql`
  ${GYM_DATA_FRAGMENT}
  ${PASS_DATA_IN_APP_BOOKING_FRAGMENT}
  ${PASS_DATA_SESSION_WITH_ERP_FRAGMENT}
  ${PASS_DATA_SESSION_WITHOUT_ERP_REGULAR_FRAGMENT}
  ${PASS_DATA_SESSION_WITHOUT_ERP_SPECIAL_FRAGMENT}
  ${PASS_DATA_SESSION_OPEN_ACCESS_FRAGMENT}

  mutation DP_TransferSession($input: DP_TransferSessionInput!) {
    DP_TransferSession(input: $input) {
      id
      creationDate
      lastCheckInDate
      gymUser {
        id
        email
        firstname
        lastname
      }
      state
      site {
        ...GymData
      }
      ...PassDataInAppBooking
      ...PassDataSessionWithERP
      ...PassDataSessionWithoutERPRegular
      ...PassDataSessionWithoutERPSpecial
      ...PassDataSessionOpenAccess
    }
  }
`;

const PUNCH_PASS_MUTATION = gql`
  ${GYM_DATA_FRAGMENT}
  ${PASS_DATA_IN_APP_BOOKING_FRAGMENT}
  ${PASS_DATA_SESSION_WITH_ERP_FRAGMENT}
  ${PASS_DATA_SESSION_WITHOUT_ERP_REGULAR_FRAGMENT}
  ${PASS_DATA_SESSION_WITHOUT_ERP_SPECIAL_FRAGMENT}
  ${PASS_DATA_SESSION_OPEN_ACCESS_FRAGMENT}

  mutation DP_CheckInSession($input: DP_CheckInSessionInput!) {
    DP_CheckInSession(input: $input) {
      id
      creationDate
      lastCheckInDate
      gymUser {
        id
        email
        firstname
        lastname
      }
      state
      site {
        ...GymData
      }
      ...PassDataInAppBooking
      ...PassDataSessionWithERP
      ...PassDataSessionWithoutERPRegular
      ...PassDataSessionWithoutERPSpecial
      ...PassDataSessionOpenAccess
    }
  }
`;

const LATE_CANCELLED_MUTATION = gql`
  ${GYM_DATA_FRAGMENT}
  ${PASS_DATA_IN_APP_BOOKING_FRAGMENT}
  ${PASS_DATA_SESSION_WITH_ERP_FRAGMENT}
  ${PASS_DATA_SESSION_WITHOUT_ERP_REGULAR_FRAGMENT}
  ${PASS_DATA_SESSION_WITHOUT_ERP_SPECIAL_FRAGMENT}
  ${PASS_DATA_SESSION_OPEN_ACCESS_FRAGMENT}

  mutation DP_DeclareLateCancel($input: DP_DeclareLateCancelInput!) {
    DP_DeclareLateCancel(input: $input) {
      id
      creationDate
      lastCheckInDate
      gymUser {
        id
        email
        firstname
        lastname
      }
      state
      site {
        ...GymData
      }
      ...PassDataInAppBooking
      ...PassDataSessionWithERP
      ...PassDataSessionWithoutERPRegular
      ...PassDataSessionWithoutERPSpecial
      ...PassDataSessionOpenAccess
    }
  }
`;

const SYNC_ERP_SESSIONS_MUTATION = gql`
  mutation DP_SyncErpSessions($input: DP_SyncErpSessions!) {
    DP_SyncErpSessions(input: $input) {
      id
      state
    }
  }
`;

const NO_SHOW_MUTAION = gql`
  ${GYM_DATA_FRAGMENT}
  ${PASS_DATA_IN_APP_BOOKING_FRAGMENT}
  ${PASS_DATA_SESSION_WITH_ERP_FRAGMENT}
  ${PASS_DATA_SESSION_WITHOUT_ERP_REGULAR_FRAGMENT}
  ${PASS_DATA_SESSION_WITHOUT_ERP_SPECIAL_FRAGMENT}
  ${PASS_DATA_SESSION_OPEN_ACCESS_FRAGMENT}

  mutation DP_DeclareNoShow($input: DP_DeclareNoShowInput!) {
    DP_DeclareNoShow(input: $input) {
      id
      creationDate
      lastCheckInDate
      gymUser {
        id
        email
        firstname
        lastname
      }
      state
      site {
        ...GymData
      }
      ...PassDataInAppBooking
      ...PassDataSessionWithERP
      ...PassDataSessionWithoutERPRegular
      ...PassDataSessionWithoutERPSpecial
      ...PassDataSessionOpenAccess
    }
  }
`;

/* const CANCEL_PENALTY_MUTATION = gql`
  ${GYM_DATA_FRAGMENT}
  ${PASS_DATA_IN_APP_BOOKING_FRAGMENT}
  ${PASS_DATA_SESSION_WITH_ERP_FRAGMENT}
  ${PASS_DATA_SESSION_WITH_ERP_REGULAR_FRAGMENT}
  ${PASS_DATA_SESSION_WITH_ERP_SPECIAL_FRAGMENT}
  ${PASS_DATA_SESSION_OPEN_ACCESS_FRAGMENT}

  mutation DP_DisputePenalty($input: DP_DisputePenaltyInput!) {
    DP_DisputePenalty(input: $input) {
      id
      creationDate
      lastCheckInDate
      gymUser {
        id
        email
        firstname
        lastname
      }
      state
      site {
        ...GymData
      }
      ...PassDataInAppBooking
      ...PassDataSessionWithERP
      ...PassDataSessionWithoutERPRegular
      ...PassDataSessionWithoutERPSpecial
      ...PassDataSessionOpenAccess
    }
  }
`; */

const RESOLVE_SESSION_TRANSFER_REQUEST_MUTATION = gql`
  ${GYM_DATA_FRAGMENT}
  ${PASS_DATA_IN_APP_BOOKING_FRAGMENT}
  ${PASS_DATA_SESSION_WITH_ERP_FRAGMENT}
  ${PASS_DATA_SESSION_WITHOUT_ERP_REGULAR_FRAGMENT}
  ${PASS_DATA_SESSION_WITHOUT_ERP_SPECIAL_FRAGMENT}
  ${PASS_DATA_SESSION_OPEN_ACCESS_FRAGMENT}

  mutation resolveSessionTransferRequest($input: ResolveSessionTransferRequestInput!) {
    DP_ResolveSessionTransferRequest(input: $input) {
      sessions {
        id
        creationDate
        lastCheckInDate
        gymUser {
          id
          email
          firstname
          lastname
        }
        state
        site {
          ...GymData
        }
        ...PassDataInAppBooking
        ...PassDataSessionWithERP
        ...PassDataSessionWithoutERPRegular
        ...PassDataSessionWithoutERPSpecial
        ...PassDataSessionOpenAccess
      }
    }
  }
`;

export const Update = () => {
  return (params: any) => {
    const action = (params.data && params.data.action) || params.action || 'save';

    if (action === PassActions.GPS_ANOMALY) {
      return {
        query: DECLARE_GPS_ANOMALY_MUTATION,
        variables: { input: { sessionID: usableIDToSessionID(params.data.id) } },
        parseResponse: (resp: any) => {
          if (!resp.data && !resp.data.DP_DeclareGPSAnomaly) return { data: {} };
          return {
            data: {
              ...resp.data.DP_DeclareGPSAnomaly,
              id: sessionIDToUsableID(resp.data.DP_DeclareGPSAnomaly.id),
            },
          };
        },
      };
    } else if (action === PassActions.VALIDATE_BOOKING) {
      return {
        query: TRANSFER_PASS_MUTATION,
        variables: { input: { sessionID: usableIDToSessionID(params.data.id) } },
        parseResponse: (resp: any) => {
          if (!resp.data && !resp.data.DP_TransferSession) return { data: {} };
          return {
            data: {
              ...resp.data.DP_TransferSession,
              id: sessionIDToUsableID(resp.data.DP_TransferSession.id),
            },
          };
        },
      };
    } else if (action === PassActions.PUNCH_PASS) {
      return {
        query: PUNCH_PASS_MUTATION,
        variables: { input: { sessionID: usableIDToSessionID(params.data.id) } },
        parseResponse: (resp: any) => {
          if (!resp.data && !resp.data.DP_CheckInSession) return { data: {} };
          return {
            data: {
              ...resp.data.DP_CheckInSession,
              id: sessionIDToUsableID(resp.data.DP_CheckInSession.id),
            },
          };
        },
      };
    } else if (action === PassActions.LATE_CANCELLED) {
      return {
        query: LATE_CANCELLED_MUTATION,
        variables: { input: { sessionID: usableIDToSessionID(params.data.id) } },
        parseResponse: (resp: any) => {
          if (!resp.data && !resp.data.DP_DeclareLateCancel) return { data: {} };
          return {
            data: {
              ...resp.data.DP_DeclareLateCancel,
              id: sessionIDToUsableID(resp.data.DP_DeclareLateCancel.id),
            },
          };
        },
      };
    } else if (action === PassActions.NO_SHOW) {
      return {
        query: NO_SHOW_MUTAION,
        variables: { input: { sessionID: usableIDToSessionID(params.data.id) } },
        parseResponse: (resp: any) => {
          if (!resp.data && !resp.data.DP_DeclareNoShow) return { data: {} };
          return {
            data: {
              ...resp.data.DP_DeclareNoShow,
              id: sessionIDToUsableID(resp.data.DP_DeclareNoShow.id),
            },
          };
        },
      };
    } /* else if (
      [PassActions.CANCEL_LATE_CANCELLED, PassActions.CANCEL_NO_SHOW].includes(action)
    ) {
      return {
        ...builtQuery('UPDATE', 'Pass', {
          data: { passID: data.id },
        }),
        query: CANCEL_PENALTY_MUTATION,
        parseResponse: (resp: any) => {
          if (!resp.data && !resp.data.disputePassPenalty) return { data: {} };
          return { data: resp.data.disputePassPenalty };
        },
      };
    } */ else if (
      [RequestAction.ACCEPT, RequestAction.DECLINE].includes(action)
    ) {
      const input: any = {
        sessionIDs: params.data.ids.map(usableIDToSessionID),
        resolveType: requestType[params.data.action as RequestAction],
      };
      if (params.data.declineReason) input['declineReason'] = params.data.declineReason;
      return {
        variables: { input },
        query: RESOLVE_SESSION_TRANSFER_REQUEST_MUTATION,
        parseResponse: (resp: any) => {
          if (
            !resp.data &&
            !resp.data.DP_ResolveSessionTransferRequest &&
            !resp.data.DP_ResolveSessionTransferRequest.sessions
          )
            return { data: {} };
          return {
            data: {
              ...resp.data.DP_ResolveSessionTransferRequest.sessions[0],
              id: sessionIDToUsableID(
                resp.data.DP_ResolveSessionTransferRequest.sessions[0].id
              ),
            },
          };
        },
      };
    } else if (action === PassActions.SYNC_ERP_STATUS) {
      return {
        query: SYNC_ERP_SESSIONS_MUTATION,
        variables: {
          input: { sessionIDs: params.data.sessionIDs.map(usableIDToSessionID) },
        },
        parseResponse: (resp: any) => {
          if (!resp.data && !resp.data.DP_SyncErpSessions) return { data: {} };
          return {
            data: {
              sessions: resp.data.DP_SyncErpSessions.map((session: any) => ({
                id: sessionIDToUsableID(session.id),
                state: session.state,
              })),
            },
          };
        },
      };
    } else {
      // TODO: implement something
    }
  };
};
