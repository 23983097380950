import React, { Fragment, useCallback, useState } from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceField,
  TextField,
  ReferenceInput,
  SelectInput,
  useTranslate,
  DateInput,
  AutocompleteInput,
  useRefresh,
  FunctionField,
} from 'react-admin';
import {
  getPassStatusForBookingFlows,
  SessionState,
  RequestAction,
} from '../../@types/common';
import { makeStyles, Modal, Typography } from '@material-ui/core';
import { Route, RouteChildrenProps, useHistory, useRouteMatch } from 'react-router-dom';

import BulkActionButtons from './BulkActionButtons';
import { PassShow } from './PassShow';
import { colors } from '../../gymlibTheme';
import { RequestHandlingModalContent } from './RequestHandling';
import classnames from 'classnames';
import {
  getCurrentGyms,
  shouldDisplayEmail,
  shouldDisplayCode,
  shouldDisplayOffer,
} from '../../utils';

const gyms = getCurrentGyms();
const displayEmail = shouldDisplayEmail(gyms);
const displayCode = shouldDisplayCode(gyms);
const displayOffer = shouldDisplayOffer(gyms);
const displayPurchasePrice = displayOffer; // at the moment logic to show columns is the same for both columns

const styles = makeStyles({
  filter: {
    '& p[id$="-helper-text"]': {
      display: 'none',
    },
  },
  statusField: {
    fontSize: 12,
  },
  statusFieldYellow: {
    fontSize: 12,
    backgroundColor: colors.yellow,
    color: colors.darkYellow,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 40,
    marginLeft: -12,
  },
  root: {
    '& div[data-test="bulk-actions-toolbar"]': {
      backgroundColor: colors.lightGray,
      margin: 10,
      borderRadius: 6,
      boxShadow:
        '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.25) !important',
  },
  autocompleteContainer: {
    boxShadow:
      '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)',
    zIndex: 2,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow:
      '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
    outline: 'none',
    borderRadius: 4,
    minWidth: 700,
    minHeight: 520,
    backgroundColor: 'white',
  },
});

const useFilterStyles = makeStyles({
  form: {
    marginLeft: 18,
  },
});

const PassFilter = (props: any) => {
  const translate = useTranslate();
  const classes = styles();
  const filterClasses = useFilterStyles();
  return (
    <Filter classes={filterClasses} {...props}>
      <ReferenceInput
        label="Prénom, nom, email..."
        source="userId"
        reference="GymUser"
        perPage={10}
        filterToQuery={(searchText: string) => ({ q: [searchText] })}
        alwaysOn
        className={classes.filter}
      >
        <AutocompleteInput
          matchSuggestion={() => true}
          optionText={(choice: any) => {
            if (choice.id) return `${choice.firstname} ${choice.lastname}`;
            return translate('gymChooser.all');
          }}
          options={{
            suggestionsContainerProps: {
              className: classes.autocompleteContainer,
            },
          }}
          shouldRenderSuggestions={(value: string): boolean => value.trim().length > 2}
        />
      </ReferenceInput>
      <DateInput
        source="startDate"
        label="resources.Pass.filter.startDate"
        resettable
        alwaysOn
        className={classes.filter}
      />
      <DateInput
        source="endDate"
        label="resources.Pass.filter.endDate"
        resettable
        alwaysOn
        className={classes.filter}
      />
      {gyms && (
        <SelectInput
          label="resources.Pass.filter.passStatus"
          source="state"
          allowEmpty={false}
          resettable
          className={classes.filter}
          choices={getPassStatusForBookingFlows(gyms.map((gym) => gym.bookingFlow))
            .filter((sessionState) => sessionState !== SessionState.REFUNDED)
            .map((sessionState) => ({
              id: sessionState,
              name: translate(
                'resources.Pass.fields.passStatus.' + (sessionState || 'unknown')
              ),
            }))}
          alwaysOn
          id="pass-list-status-filter"
        />
      )}
    </Filter>
  );
};

export const PassList = (props: any) => {
  const classes = styles();
  const history = useHistory();
  const refresh = useRefresh();
  const translate = useTranslate();
  const [lastUrl, setLastUrl] = useState('/Pass');

  const match2 = useRouteMatch<{ id: string; tab: string; action: RequestAction }>({
    path: '/Pass/:id/:tab/:action?',
  });
  const displayPassData = !!(
    match2?.params.id &&
    match2?.params.tab &&
    !match2?.params.action
  );
  const displayAcceptAction = !!(
    match2?.params.id &&
    match2?.params.tab &&
    match2?.params.action === RequestAction.ACCEPT
  );
  const displayDeclineAction = !!(
    match2?.params.id &&
    match2?.params.tab &&
    match2?.params.action === RequestAction.DECLINE
  );

  if (
    history.location.search &&
    lastUrl !== `${history.location.pathname}${history.location.search}`
  ) {
    setLastUrl(`${history.location.pathname}${history.location.search}`);
  }

  const closeModal = useCallback(() => {
    history.push(lastUrl);
  }, [history, lastUrl]);

  const StateField = (props: any) => (
    <div
      className={classnames({
        [classes.statusField]: props.record.state !== SessionState.REQUEST_SENT,
        [classes.statusFieldYellow]: props.record.state === SessionState.REQUEST_SENT,
      })}
    >
      <span>
        {translate(
          'resources.Pass.fields.passStatus.' +
            ((props.record && props.record.state) || 'unknown')
        )}
      </span>
    </div>
  );

  return (
    <div className={classes.root}>
      <Route path="/Pass/:id">
        {({ match }: RouteChildrenProps<{ id: string; tab: string; action: string }>) => {
          return (
            <Fragment>
              <List
                {...props}
                bulkActionButtons={<BulkActionButtons />}
                filters={<PassFilter />}
                filterDefaultValues={{
                  gymIDs: gyms.map((gym) => gym.id),
                }}
                undoable="false"
                sort={{ field: 'sessionDate', order: 'DESC' }}
                actions={null}
                perPage={25}
                title={<></>}
                id="pass-list"
                className={classes.root}
              >
                <Datagrid
                  rowClick={(id: string, basePath: string) => `${basePath}/${id}/details`}
                  hasBulkActions={true}
                >
                  {gyms.length > 1 && (
                    <TextField source="site.name" label="resources.Pass.fields.gymName" />
                  )}
                  {displayCode && (
                    <TextField
                      source="reference"
                      label="resources.Pass.fields.code"
                      sortable={false}
                    />
                  )}
                  {displayEmail && (
                    <FunctionField
                      label="resources.Pass.fields.userName"
                      render={(record: any) => {
                        return (
                          <Typography id="pass-list-username">
                            {record.gymUser.firstname + ' ' + record.gymUser.lastname}
                          </Typography>
                        );
                      }}
                    />
                  )}
                  {displayEmail && (
                    <ReferenceField
                      label="resources.Pass.fields.userEmail"
                      source="gymUser.id"
                      reference="GymUser"
                      sortable={false}
                      link="edit"
                    >
                      <TextField source="email" id="pass-list-email" />
                    </ReferenceField>
                  )}
                  {!displayEmail && (
                    <ReferenceField
                      label="resources.Pass.fields.userName"
                      source="gymUser.id"
                      reference="GymUser"
                      sortable={false}
                      link="edit"
                    >
                      <FunctionField
                        render={(record: any) => {
                          return (
                            <Typography id="pass-list-username">
                              {record.firstname + ' ' + record.lastname}
                            </Typography>
                          );
                        }}
                      />
                    </ReferenceField>
                  )}
                  {/* <FunctionField
                    label="resources.Pass.fields.lastValidationDate"
                    sortBy="lastCheckInDate"
                    render={(record: any) => {
                      if (!record) return null;
                      if (record.lastCheckInDate)
                        return (
                          <span>
                            {moment(record.lastCheckInDate).format('DD/MM/YYYY HH:mm:ss')}
                          </span>
                        );
                      if (record.penaltyDate)
                        return (
                          <span>
                            {moment(record.penaltyDate).format('DD/MM/YYYY HH:mm:ss')}
                          </span>
                        );
                    }}
                  /> */}
                  {displayOffer && (
                    <FunctionField
                      label="Offre"
                      render={(record: any) => {
                        if (!record) return null;

                        return record.gymOffer ? (
                          <span>{record.gymOffer.name}</span>
                        ) : (
                          <span>Aucune offre</span>
                        );
                      }}
                    />
                  )}
                  {displayPurchasePrice && (
                    <FunctionField
                      label="Valeur à l'achat"
                      render={(record: any) => {
                        if (!record) return null;
                        return record.publicPrice ? (
                          <span>{Number(record.publicPrice / 100).toFixed(2)}€</span>
                        ) : (
                          <span>N/A</span>
                        );
                      }}
                    />
                  )}
                  <DateField source="sessionDate" showTime id="pass-list-start-date" />
                  <DateField
                    source="expirationDate"
                    showTime
                    id="pass-list-expiration-date"
                  />
                  <StateField label="resources.Pass.fields.status" />
                </Datagrid>
              </List>
              <Modal
                open={displayPassData || displayAcceptAction || displayDeclineAction}
                onClose={closeModal}
                className={classes.modal}
                BackdropProps={{ className: classes.backdrop }}
              >
                <div className={classes.modalContent}>
                  {displayPassData && (
                    <PassShow
                      onCancel={closeModal}
                      id={(match as any).params.id}
                      {...props}
                    />
                  )}
                  {displayAcceptAction && (
                    <RequestHandlingModalContent
                      action={RequestAction.ACCEPT}
                      sessionIDs={[(match as any).params.id]}
                      onAction={() => {
                        closeModal();
                        refresh();
                      }}
                      {...props}
                    />
                  )}
                  {displayDeclineAction && (
                    <RequestHandlingModalContent
                      action={RequestAction.DECLINE}
                      sessionIDs={[(match as any).params.id]}
                      onAction={() => {
                        closeModal();
                        refresh();
                      }}
                      {...props}
                    />
                  )}
                </div>
              </Modal>
            </Fragment>
          );
        }}
      </Route>
    </div>
  );
};
