import gql from 'graphql-tag';

import { buildQuery } from 'ra-data-graphql-simple';
import { getCurrentGyms } from '../../../utils';

const GET_LIST_QUERY = gql`
  query allGymUsers(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: GymUserFilter
  ) {
    items: allGymUsers(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      email
      firstname
      lastname
      picture
      punchedPasses
      birthday
    }
    total: _allGymUsersMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
    }
  }
`;

export const GetList = (introspection: any) => {
  const builtQuery = buildQuery(introspection);
  return (params: any) => {
    const gyms = getCurrentGyms();

    params.filter = {
      gymIDs: gyms.map((gym) => gym.id),
      ...params.filter,
    };

    return {
      // Use the default query variables and parseResponse
      ...builtQuery('GET_LIST', 'GymUser', params),
      // Override the query
      query: GET_LIST_QUERY,
    };
  };
};

export const GetMany = (introspection: any) => {
  const builtQuery = buildQuery(introspection);
  return (params: any) => {
    const gyms = getCurrentGyms();

    const preparedVariables: any = {
      // We need to set the page to -1 because the Pagination component will start at 1 and not 0
      page: params.pagination ? params.pagination.page - 1 : undefined,
      perPage: params.pagination ? params.pagination.perPage : undefined,
      sortField: params.sort ? params.sort.field : undefined,
      sortOrder: params.sort ? params.sort.order : undefined,
      filter: { ...params },
    };

    preparedVariables.filter['gymIDs'] = gyms.map((gym) => gym.id);

    return {
      // Use the default query variables and parseResponse
      ...builtQuery('GET_MANY', 'GymUser', params),
      // Override the query
      variables: preparedVariables,
      query: GET_LIST_QUERY,
    };
  };
};

export const GetManyReference = (introspection: any) => {
  const builtQuery = buildQuery(introspection);
  return (params: any) => {
    const gyms = getCurrentGyms();

    const preparedVariables: any = {
      // We need to set the page to -1 because the Pagination component will start at 1 and not 0
      page: params.pagination ? params.pagination.page - 1 : undefined,
      perPage: params.pagination ? params.pagination.perPage : undefined,
      sortField: params.sort ? params.sort.field : undefined,
      sortOrder: params.sort ? params.sort.order : undefined,
      filter: { ...params },
    };

    preparedVariables.filter['gymIDs'] = gyms.map((gym) => gym.id);

    return {
      // Use the default query variables and parseResponse
      ...builtQuery('GET_MANY_REFERENCE', 'GymUser', params),
      variables: preparedVariables,
      // Override the query
      query: GET_LIST_QUERY,
    };
  };
};
