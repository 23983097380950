import { getIntrospectionQuery } from 'graphql';
import gql from 'graphql-tag';

import { GET_LIST, GET_ONE } from 'ra-core';
import { ALL_TYPES } from 'ra-data-graphql';

const filterTypesByIncludeExclude = ({
  include,
  exclude,
}: {
  include: any;
  exclude: any;
}) => {
  if (Array.isArray(include)) {
    return (type: any) => include.includes(type.name);
  }

  if (typeof include === 'function') {
    return (type: any) => include(type);
  }

  if (Array.isArray(exclude)) {
    return (type: any) => !exclude.includes(type.name);
  }

  if (typeof exclude === 'function') {
    return (type: any) => !exclude(type);
  }

  return () => true;
};

export const resolveIntrospection = (
  queryRename: { [key: string]: string },
  typeForced: string[]
) => {
  return async (client: any, options: any) => {
    const schema = options.schema
      ? options.schema
      : await client
          .query({
            fetchPolicy: 'network-only',
            query: gql`
              ${getIntrospectionQuery({ descriptions: false })}
            `,
          })
          .then(({ data: { __schema } }: { data: { __schema: any } }) => __schema);

    const queries = schema.types.reduce((acc: any, type: any) => {
      if (type.name !== schema.queryType.name && type.name !== schema.mutationType.name)
        return acc;

      return [...acc, ...type.fields];
    }, []);

    // Here we "forge" fake queries to match the naming convention of the dataProvider
    Object.keys(queryRename).forEach((sourceQueryName: string) => {
      const originalQuery = queries.filter((type: any) => type.name === sourceQueryName);
      if (originalQuery.length > 0) {
        originalQuery[0].name = queryRename[sourceQueryName];
        queries.push(originalQuery[0]);
      }
    });

    const types = schema.types.filter(
      (type: any) =>
        type.name !== schema.queryType.name && type.name !== schema.mutationType.name
    );

    const isResource = (type: any) => {
      return (
        typeForced.some((typeName: string) => type.name === typeName) ||
        (queries.some((query: any) => {
          return query.name === options.operationNames[GET_LIST](type);
        }) &&
          queries.some((query: any) => {
            return query.name === options.operationNames[GET_ONE](type);
          }))
      );
    };

    const buildResource = (type: any) =>
      ALL_TYPES.reduce(
        (acc: any, aorFetchType: any) => ({
          ...acc,
          [aorFetchType]: queries.find(
            (query: any) =>
              options.operationNames[aorFetchType] &&
              query.name === options.operationNames[aorFetchType](type)
          ),
        }),
        { type }
      );

    const potentialResources = types.filter(isResource);

    const filteredResources = potentialResources.filter(
      filterTypesByIncludeExclude(options)
    );
    const resources = filteredResources.map(buildResource);

    return {
      types,
      queries,
      resources,
      schema,
    };
  };
};
