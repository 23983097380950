// import CancelIcon from '@material-ui/icons/Cancel';
import { LinearProgress, makeStyles, Modal } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/ErrorOutline';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import GpsOffIcon from '@material-ui/icons/GpsOff';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import WatchLateIcon from '@material-ui/icons/WatchLaterOutlined';
import React, { useState } from 'react';
import { useQueryWithStore, useRefresh, useUnselectAll } from 'react-admin';
import {
  BookingFlow,
  PassActions,
  RequestAction,
  SessionState,
} from '../../@types/common';
import { GenericActionButton, GenericBulkActionButton } from './GenericActionButton';
import {
  RequestHandlingBulkButton,
  RequestHandlingModalContent,
} from './RequestHandling';

export const styles = makeStyles({
  modal: {
    height: '100%',
    width: '100%',
  },
  modalActionRequest: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.25) !important',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow:
      '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
    outline: 'none',
    borderRadius: 4,
    minWidth: 700,
    minHeight: 520,
    backgroundColor: 'white',
  },
  innerModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    backgroundColor: 'white',
    padding: '30px',
  },
  progress: {
    marginBottom: '20px',
  },
});

export default function BulkActionsButtons(props: any): JSX.Element {
  const [openBlockingModal, setOpenBlockingModal] = useState({
    total: 0,
    done: 0,
  });
  const [openActionRequestModal, setOpenActionRequestModal] = useState(false);
  const [requestAction, setRequestAction] = useState(RequestAction.ACCEPT);
  const refresh = useRefresh();
  const classes = styles();
  const unselectAll = useUnselectAll();
  const { data } = useQueryWithStore(
    {
      type: 'getList',
      resource: 'Pass',
      payload: {
        filter: {
          ids: props.selectedIds,
        },
        pagination: { page: 1, perPage: props.selectedIds.length },
        sort: {
          field: 'lastCheckInDate',
          order: 'DESC',
        },
      },
    },
    {},
    (state: any) =>
      props.selectedIds.map((id: string) => state.admin.resources.Pass.data[id])
  );

  const closeRequestActionModal = () => {
    setOpenActionRequestModal(false);
  };

  const closeRequestActionModalAndRefresh = () => {
    unselectAll('Pass');
    closeRequestActionModal();
    refresh();
  };

  if (!data || data.length === 0) return <></>;
  return (
    <>
      {data.every(
        (session: any) =>
          session.state === SessionState.BOOKED &&
          session.site.bookingFlow === BookingFlow.BOOKING_WITH_ERP
      ) && (
        <GenericBulkActionButton
          {...props}
          action={PassActions.NO_SHOW}
          data={data}
          blockUser={setOpenBlockingModal}
          isUserBlocked={openBlockingModal.total - openBlockingModal.done > 0}
          icon={<WarningIcon />}
        />
      )}
      {data.every(
        (session: any) =>
          session.state === SessionState.BOOKED &&
          session.site.bookingFlow === BookingFlow.BOOKING_WITH_ERP
      ) && (
        <GenericBulkActionButton
          {...props}
          action={PassActions.LATE_CANCELLED}
          data={data}
          blockUser={setOpenBlockingModal}
          isUserBlocked={openBlockingModal.total - openBlockingModal.done > 0}
          icon={<WatchLateIcon />}
        />
      )}
      {data.every((session: any) =>
        [SessionState.USABLE, SessionState.BOOKED].includes(session.state)
      ) && (
        <GenericBulkActionButton
          {...props}
          action={PassActions.GPS_ANOMALY}
          data={data}
          blockUser={setOpenBlockingModal}
          isUserBlocked={openBlockingModal.total - openBlockingModal.done > 0}
          icon={<GpsOffIcon />}
        />
      )}
      {data.every(
        (session: any) =>
          session.state !== SessionState.USABLE &&
          session.site.bookingFlow === BookingFlow.UNKNOWN
      ) && (
        <GenericBulkActionButton
          {...props}
          action={PassActions.PUNCH_PASS}
          data={data}
          blockUser={setOpenBlockingModal}
          isUserBlocked={openBlockingModal.total - openBlockingModal.done > 0}
          icon={<CheckCircleIcon />}
        />
      )}
      {data.every(
        (session: any) =>
          session.state === SessionState.CREATED &&
          [BookingFlow.BOOKING_WITHOUT_ERP_SPECIAL].includes(session.site.bookingFlow)
      ) && (
        <GenericBulkActionButton
          {...props}
          action={PassActions.VALIDATE_BOOKING}
          data={data}
          blockUser={setOpenBlockingModal}
          isUserBlocked={openBlockingModal.total - openBlockingModal.done > 0}
          icon={<EventAvailableIcon />}
        />
      )}
      {data.every((session: any) => session.state === SessionState.REQUEST_SENT) && (
        <RequestHandlingBulkButton
          action={RequestAction.DECLINE}
          data={data}
          onClick={() => {
            setOpenActionRequestModal(true);
            setRequestAction(RequestAction.DECLINE);
          }}
        />
      )}
      {data.every((session: any) => session.state === SessionState.REQUEST_SENT) && (
        <RequestHandlingBulkButton
          action={RequestAction.ACCEPT}
          data={data}
          onClick={() => {
            setOpenActionRequestModal(true);
            setRequestAction(RequestAction.ACCEPT);
          }}
        />
      )}
      {data.every(
        (session: any) =>
          session.state === SessionState.CREDIT_TRANSFERED &&
          [BookingFlow.BOOKING_WITH_ERP].includes(session.site.bookingFlow)
      ) && (
        <GenericActionButton
          action={PassActions.SYNC_ERP_STATUS}
          icon={<SettingsInputComponentIcon />}
          data={{ sessionIDs: data.map(({ id }: { id: string }) => id) }}
        />
      )}
      <Modal
        open={openActionRequestModal}
        className={classes.modalActionRequest}
        BackdropProps={{ className: classes.backdrop }}
        onClose={closeRequestActionModal}
      >
        <div className={classes.modalContent}>
          {requestAction === RequestAction.ACCEPT && (
            <RequestHandlingModalContent
              action={RequestAction.ACCEPT}
              sessionIDs={props.selectedIds}
              displayBack={false}
              displayCancel
              cancelAction={closeRequestActionModal}
              onAction={closeRequestActionModalAndRefresh}
              {...props}
            />
          )}
          {requestAction === RequestAction.DECLINE && (
            <RequestHandlingModalContent
              action={RequestAction.DECLINE}
              sessionIDs={props.selectedIds}
              displayBack={false}
              displayCancel
              cancelAction={closeRequestActionModal}
              onAction={closeRequestActionModalAndRefresh}
              {...props}
            />
          )}
        </div>
      </Modal>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={openBlockingModal.total - openBlockingModal.done > 0}
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        className={classes.modal}
      >
        <div className={classes.innerModal}>
          <h2 id="simple-modal-title">
            {openBlockingModal.done} / {openBlockingModal.total}
          </h2>
          <LinearProgress
            variant="determinate"
            value={(openBlockingModal.done / openBlockingModal.total) * 100}
            className={classes.progress}
          />
        </div>
      </Modal>
    </>
  );
}
