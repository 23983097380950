import React, { FunctionComponent, useState } from 'react';
import {
  makeStyles,
  Typography,
  TextField,
  Button,
  CssBaseline,
  Card,
  Container,
  CircularProgress,
  ThemeProvider,
} from '@material-ui/core';
import {
  useRedirect,
  useNotify,
  useDataProvider,
  useTranslate,
  Notification,
} from 'react-admin';
import { Field, Form } from 'react-final-form';
import { sleep } from '../utils';
import gymlibTheme from '../gymlibTheme';

const styles = makeStyles({
  withShadow: {
    boxShadow:
      '0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)',
  },
  paper: {
    margin: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    width: '100%',
    marginTop: '15px',
  },
  submit: {
    marginTop: '30px',
  },
  logo: {
    display: 'block',
    margin: 'auto',
    height: '45px',
  },
  logoContainer: {
    height: '15vh',
    minHeight: '100px',
    display: 'flex',
  },
  icon: {
    marginRight: '5px',
  },
});

interface SendPasswordResetProps {
  match: any;
}

interface FormData {
  email: string;
}

const SendPasswordReset: FunctionComponent<SendPasswordResetProps> = ({
  match,
}: SendPasswordResetProps) => {
  const classes = styles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();
  const [loading, setLoading] = useState(false);

  const submit = async (values: FormData) => {
    setLoading(true);
    try {
      await dataProvider.create('PasswordReset', {
        data: {
          email: values.email,
        },
      });
      await sleep(500);
      notify('pages.sendPasswordReset.genericResponse', 'info');
    } catch (e) {
      await sleep(500);
      notify('pages.sendPasswordReset.classicError', 'warning');
    }
    redirect('/login');
  };

  return (
    <ThemeProvider theme={gymlibTheme}>
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <div className={classes.logoContainer}>
          <img className={classes.logo} alt="" src={require('../images/logo.svg')} />
        </div>
        <Card className={classes.withShadow}>
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              {translate('pages.sendPasswordReset.title')}
            </Typography>
            <Typography component="caption" variant="caption">
              {translate('pages.sendPasswordReset.caption')}
            </Typography>
            <Form
              onSubmit={submit}
              validate={(values) => {
                const errors = { email: '' };
                if (values.email) {
                  delete errors.email;
                } else {
                  errors.email = translate('ra.validation.required');
                }
                return errors;
              }}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className={classes.form}>
                  <Field
                    id="email"
                    label="Email"
                    autoComplete="email"
                    name="email"
                    defaultValue={(match && match.params && match.params.email) || null}
                    disabled={loading}
                  >
                    {(field) => (
                      <TextField
                        error={!!(field.meta.touched && field.meta.error)}
                        helperText={field.meta.touched && field.meta.error}
                        autoFocus
                        margin="normal"
                        required
                        fullWidth
                        variant="outlined"
                        {...field.input}
                        {...field}
                      />
                    )}
                  </Field>
                  <Button
                    type="submit"
                    fullWidth
                    color="primary"
                    variant="contained"
                    id="submit-button"
                    className={classes.submit}
                    disabled={loading}
                  >
                    {loading && (
                      <CircularProgress
                        className={classes.icon}
                        size={18}
                        thickness={2}
                      />
                    )}
                    {translate('pages.sendPasswordReset.button')}
                  </Button>
                </form>
              )}
            </Form>
          </div>
        </Card>
        <Notification id="snack-bar" />
      </Container>
    </ThemeProvider>
  );
};

export default SendPasswordReset;
